import {useSelector} from "react-redux";
import InputFeedback from "./InputFeedback";

const CheckboxInput = ({name, label, placeholder, value, handleInputChange, disabled = false, checked = false}) => {

    const errors = useSelector(state => state.errors);


    const renderClass = () => {
        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors[name]) {
                return "form-check-input form-control is-invalid"
            }

        }

        return "form-check-input"
    }

    const renderLabel = () => {
        if(label) {
            return (<label>{label}</label>)
        }
    }


    return (
        <div className="form-check">

            <input
                type="checkbox"
                className={renderClass()}
                aria-invalid="true"
                value={value}
                name={name}
                onChange={handleInputChange}
                disabled={disabled}
                checked={checked}
            />
            {renderLabel()}
            <InputFeedback name={name} />

        </div>
    )


};

export default CheckboxInput;