import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Button,
    Table
} from 'reactstrap';
import AddCommittee from "./Forms/AddCommittee";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCommittees, deleteCommittee, addCommittee, editCommittee} from "../actions/committeeAction";
import {useTranslation} from "react-i18next";

const Committee = (props) => {

    const {t} = useTranslation();
    const [modal, setModal] = useState(false)
    const [committeeName, setCommitteeName] = useState('');
    const [committeeId, setCommitteeId] = useState('');
    const {committees, isWaiting} = useSelector(state => state.committees);
    const {organisation} = useSelector(state => state.organisation);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: "COMMITTEE_LOADING"})
        dispatch(getCommittees())

        return function cleanup() {
            dispatch({type: "CLEAR_ERRORS"});
            dispatch({type: "CLEAR_COMMITTEES"});
        }

    }, [dispatch]);

    useEffect(() => {
        if (modal && !isWaiting) {
            setModal(false);
        } // eslint-disable-next-line
    }, [isWaiting])


    const toggle = () => setModal(!modal);

    /**
     *
     * Handler
     *
     */

    const handleClose = () => {
        setCommitteeName('')
        setCommitteeId('')
        dispatch({type: "CLEAR_ERRORS"});
        dispatch({type: "COMMITTEE_LOADING_ABORT"})
    }

    const inputChangeHandler = (e) => {
        setCommitteeName(e.target.value)
    }
    const deleteButtonHandler = (e) => {
        dispatch(deleteCommittee(e))
    }

    const editButtonHandler = (id, name) => {
        setCommitteeId(id)
        setCommitteeName(name);
        toggle();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({type: "CLEAR_ERRORS"});
        if (committeeId) {
            dispatch(editCommittee(committeeId, committeeName))
        } else {
            dispatch(addCommittee(committeeName))
        }

    }

    const renderNextMeeting = (committee) => {
        let today = new Date();
        let date = "-";
        let time = "";
        if (committee.meetings.length !== 0) {
            for (let x = 0; x < committee.meetings.length; x++) {
                if (new Date(committee.meetings[x].date) >= today) {
                    date = new Date(committee.meetings[x].date).toLocaleDateString('de-DE', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })
                    time = committee.meetings[x].time + ' Uhr'
                    break;
                }
            }
            if (date === "-") {
                return <td className="flex-td grayed-out"><i className="mdi mdi-calendar-today"></i>{t('placeholder.pending')}</td>
            }
            return <td className="flex-td"><i className="mdi mdi-calendar-today"></i>{date + ', ' + time}</td>;

        } else {
            return <td className="flex-td grayed-out"><i className="mdi mdi-calendar-today"></i>{t('placeholder.pending')}</td>
        }
    }

    const renderCommittees = () => {
        if (committees.length) {
            return (
                <Table className="mb-0" responsive>
                    <thead>
                    <tr>
                        <th>{t('general.name')}</th>
                        <th>{t('general.meeting')}</th>
                        <th>{t('general.next_meeting')}</th>
                        <th>{t('general.action')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {committees.map(committee => {
                        return (
                            <tr key={committee.id} className="committee-list">
                                <td>
                                    <Link to={`/committee/${committee.id}`} className="flex-td"><i className="mdi mdi-home-outline"></i>{committee.name}
                                    </Link></td>
                                <td>{committee.meetings_count}</td>
                                {renderNextMeeting(committee)}
                                <td className="tableColumnMinSize">
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color="primary">
                                            {t('general.action')}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => editButtonHandler(committee.id, committee.name)}>Bearbeiten</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                if (window.confirm(t('confirm.delete_committee'))) deleteButtonHandler(committee.id)
                                            }}>Löschen</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>

                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>

            )

        } else {
            if (!isWaiting) {
                return t('general.no_committee')
            } else {
                return (
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                )
            }

        }
    }


    const renderCommitteeModal = () => {
        if (modal) {
            return (
                <AddCommittee
                    onColsed={handleClose}
                    modal={modal}
                    toggle={toggle}
                    handleInputChange={inputChangeHandler}
                    handleSubmit={handleSubmit}
                    inputValue={committeeName}/>
            )
        } else {
            return null;
        }
    }

    return (
        <Container fluid>
            <div className="headRow">
                <Row>
                    <Col>
                        <h1>{t('headline.committee_overview')} {organisation ? organisation.name : ""}</h1>
                    </Col>
                </Row>
                <div className="topButton">
                    <Button color="success" onClick={toggle}>{t('button.committee_create')}</Button>
                    {renderCommitteeModal()}
                </div>
            </div>
            <Row className="overviewTable">
                <Col>
                    <h2>{t('general.committee')}</h2>
                </Col>
                <Col>
                    {renderCommittees()}
                </Col>

            </Row>
        </Container>
    );

};

export default Committee;

