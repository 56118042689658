import {Modal, ModalBody, ModalHeader} from "reactstrap";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getResult} from "../../actions/resultAction";
import ResultChart from "../ResultChart";
import ResultDetailLog from "../ResultDetailLog";
import {showAgendaItem} from "../../actions/agendaItemAction";
import {useTranslation} from "react-i18next";

const Result = ({toggle, modal, voting}) => {
    const {t} = useTranslation();
    const {voted_count} = useSelector(state => state.result);
    const {selected_agenda_item} = useSelector(state => state.agenda_items);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getResult(voting.id))
        dispatch(showAgendaItem(voting.agenda_item_id))

        return function cleanup() {
            dispatch({type: "CLEAR_RESULT"});
        }
    }, [dispatch])


    const renderFinalResult = () => {

        if (!voting) return null;
        if (!voting.result) return null;
        if (voting.result === "") return null;

        let voting_option  = voting.voting_options.filter(voting_option => voting_option.id === voting.result.voting_option_id)

        return (
            <div className="resultBox">
                <p>Endergebnis</p>
                <p>Mit {voting.result.votes} Stimmen wurde
                    für {voting_option[0].label} gestimmt.</p>
            </div>
        )
    }


    return (
        <Modal size={!voted_count ? "" : (voting.type === 'select' || voting.type === 'multi_select' || voting.type === 'read_and_confirm' ? "xl" : "")} isOpen={modal} backdrop="static" toggle={toggle} className='modal-dialog-centered result-modal'>
            <ModalHeader toggle={toggle}>
                Ergebnis: {voting.title} <br/> <br/>
                TOP {selected_agenda_item && selected_agenda_item.order}: {selected_agenda_item && (selected_agenda_item.title)}
                <Link title="In neuem Tab öffnen" target="_blank" to={`/result/${voting.id}`} class="new-tab-link"><i className="mdi mdi-open-in-new"/></Link>
            </ModalHeader>
            <ModalBody>
                <div className="result-diagram">
                    {<ResultChart voting={voting}/>}
                    {renderFinalResult()}
                </div>
                {<ResultDetailLog/>}
            </ModalBody>
        </Modal>
    )
};

export default Result;