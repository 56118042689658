const initState = {
    participants: [],
    manual_participants: [],
    meeting_leader: [],
    quorum: [],
    isWaiting: false
};




const participantReducer = (state = initState, action) => {

    switch (action.type) {
        case "FETCH_PARTICIPANTS":
            return {...state, participants: action.payload.participants, meeting_leader: action.payload.meeting_leader,quorum: action.payload.quorum, isWaiting: false};
        case "FETCH_MANUAL_PARTICIPANTS":
            return {...state, manual_participants: action.payload.participants, isWaiting: false};
        case "IMPORT_PARTICIPANTS":
            return {...state, participants: action.payload.participants, quorum: action.payload.quorum,isWaiting: false};
        case "DELETE_PARTICIPANT":
            return {...state, participants: state.participants.filter((item) => item.id !== action.payload.id), quorum: action.payload.quorum, isWaiting: false};
        case "ADD_PARTICIPANT":
            return {...state, participants: state.participants.concat(action.payload.participant), quorum: action.payload.quorum, isWaiting: false};
        case "CLEAR_PARTICIPANTS":
            return initState;
        case "DELETE_ALL_PARTICIPANT":
            return initState;
        case "PARTICIPANTS_LOADING":
            return {...state, isWaiting: true};
        case "PARTICIPANTS_LOADING_ABORT":
            return {...state, isWaiting: false};
        case "EDIT_PARTICIPANT":
            const newState = state.participants.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.participant
                }
                return item
            });
            return {...state, participants: newState, isWaiting: false, quorum: action.payload.quorum};
        case "EDIT_PARTICIPANTS":
            let newParticipants = state.participants.map((item) => {

                for (let i = 0; i < action.payload.participants.length; i++) {
                    if (action.payload.participants[i].id === item.id) {
                        return action.payload.participants[i]
                    }
                }
                return item
            });
            return {...state, participants: newParticipants, isWaiting: false, quorum: action.payload.quorum};
        default:
            return state
    }
};


export default participantReducer;