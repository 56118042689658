import axios from "axios";
import {errorBuilder} from "./errorAction";
import {getToken} from "../_services/userService";


export const getCommittees = () => async (dispatch) => {
    dispatch({type:"COMMITTEE_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/committee`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(function (response) {
            dispatch({
                type: "FETCH_COMMITTEES",
                payload: {
                    committees: response.data.data
                }
            });
        }).catch(function (error) {
            dispatch(errorBuilder(error));
        });
};

export const addCommittee = (committeeName) => async (dispatch) => {
    dispatch({type:"COMMITTEE_LOADING"})
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/committee`, {
        name: committeeName
    },{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "ADD_COMMITTEE",
            payload: {
                committee: response.data.data
            }
        })
    }).catch(error => {
        dispatch(errorBuilder(error));
    });
}

export const editCommittee = (id, committeeName) => async (dispatch) => {
    dispatch({type:"COMMITTEE_LOADING"})
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/committee/${id}`, {
        name: committeeName
    },{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
                dispatch({
                    type: "EDIT_COMMITTEE",
                    payload: {
                        committee: response.data.data,
                        id: id
                    }
                })
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

};


export const deleteCommittee = (id) => (dispatch) => {
    dispatch({type:"COMMITTEE_LOADING"})
    axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/committee/${id}`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "DELETE_COMMITTEE",
                payload: {
                    id: id
                }
            })
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

}


export const showCommittee = (id) => async (dispatch) => {
    dispatch({type:"COMMITTEE_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/committee/${id}`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "SHOW_COMMITTEE",
                payload: {
                    committee: response.data.data
                }
            })
        }).catch(error => {
            dispatch(errorBuilder(error));
        });
}