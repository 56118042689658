import axios from "axios";
import {getToken} from "../_services/userService";
import {errorBuilder} from "./errorAction";

export const getAgendaItemTemplates = () => async(dispatch) =>  {
    dispatch({type:"AGENDA_ITEMS_TEMPLATES_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/templates/agenda_item`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type:"FETCH_AGENDA_ITEM_TEMPLATES",
                payload: {
                    agenda_item_templates: response.data.data
                }
            });

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

};

export const addAgendaItemTemplate = (agenda_item_data) => async(dispatch) =>  {
    dispatch({type:"AGENDA_ITEM_TEMPLATES_LOADING"})

    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/templates/agenda_item`, agenda_item_data, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "ADD_AGENDA_ITEM_TEMPLATE",
            payload:{
                agenda_item_template: response.data.data,
            }
        });

    }).catch(error => {
        dispatch(errorBuilder(error));
    });
};

export const showAgendaItemTemplate = (agenda_item_template_id) => async (dispatch) => {
    dispatch({type:"AGENDA_ITEM_TEMPLATES_LOADING"});
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/templates/agenda_item/${agenda_item_template_id}`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "SHOW_AGENDA_ITEM_TEMPLATE",
                payload: {
                    agenda_item_template: response.data.data
                }
            });

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
}


export const addVotingTemplateToAgendaItemTemplate = (agenda_item_template_id, form_data) => async (dispatch) => {
    dispatch({type:"AGENDA_ITEM_TEMPLATES_LOADING"});
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/templates/agenda_item/${agenda_item_template_id}/voting`, form_data,
        {
            headers: {
                'Authorization': "Bearer " + getToken()
            }
        }).then(response => {
            dispatch({
                type: "SHOW_AGENDA_ITEM_TEMPLATE",
                payload: {
                    agenda_item_template: response.data.data
                }
            });
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
}

export const removeVotingTemplateFromAgendaItemTemplate = (agenda_item_template_id,voting_template_id) => async (dispatch) => {
    dispatch({type:"AGENDA_ITEM_TEMPLATES_LOADING"});
    await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/templates/agenda_item/${agenda_item_template_id}/voting/${voting_template_id}`,
        {
            headers: {
                'Authorization': "Bearer " + getToken()
            }
        }).then(response => {
        dispatch({
            type: "SHOW_AGENDA_ITEM_TEMPLATE",
            payload: {
                agenda_item_template: response.data.data
            }
        });
    })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
}



export const deleteAgendaItemTemplate = (id) => (dispatch) => {
    dispatch({type:"AGENDA_ITEM_TEMPLATES_LOADING"})
    axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/templates/agenda_item/${id}`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "DELETE_AGENDA_ITEM_TEMPLATE",
                payload: {
                    id: id
                }
            })
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });


}

export const editAgendaItemTemplate = (form_data) => async (dispatch) => {
    dispatch({type:"AGENDA_ITEM_TEMPLATES_LOADING"})
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/templates/agenda_item/${form_data.id}`,form_data ,
        {
            headers: {
                'Authorization': "Bearer " + getToken()
            }
        })
        .then(response => {
            dispatch({
                type: "EDIT_AGENDA_ITEM_TEMPLATE",
                payload: {
                    id: form_data.id,
                    agenda_item_template: response.data.data
                }
            })
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
}