import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        debug:true,
        lng:'de',
        interpolation:{
            escapeValue: false
        },
        resources:{
            de:{
                translation:{
                    general: {
                        create: "anlegen",
                        edit: "Bearbeiten",
                        delete: "Löschen",
                        abort:'Abbrechen',
                        committee: "Ausschuss",
                        meeting: "Versammlung",
                        meetings: "Versammlungen",
                        voting: "Abstimmung",
                        voting_type: "Art der Abstimmung",
                        agenda_item_short: "TOP",
                        agenda_item: "Tagesordnungspunkt",
                        template: "Vorlagen",
                        name: "Name",
                        next_meeting:"Nächste Versammlung",
                        action: "Aktion",
                        no_committee:"Zurzeit sind keine Ausschüsse angelegt",
                        no_meetings: "Zurzeit sind keine Versammlungen für Sie innerhalb dieses Ausschuss angelegt",
                        no_agenda_items_template: "Zurzeit sind keine TOP Templates angelegt",
                        meeting_type: "Versammlungsart",
                        appointment: "Termin",
                        place: "Ort",
                        closed: "geschlossen",
                        attention: "Beachten Sie:",
                        selection: "Auswahl",
                        no_voting_template:"Es wurden noch keine Abstimmungsvorlagen angelegt.",
                        motion: "Beschlussantrag",
                        type: "Typ",
                        read_more: "Vollständigen Text anzeigen",
                        no_preliminary_remark: 'Es wurde keine Vorbemerkung hinterlegt',
                        no_motion: 'Es wurde kein Beschlussantrag hinterlegt',
                        no_note: 'Es wurde keine Notiz hinterlegt',
                        appendix: 'Anhang',
                        participant:"Teilnehmer",
                        intake:"Eingabe",
                        no_votes: "Bisher wurden keine Stimmen abgegeben.",
                        result:{
                            accepted: "Der Antrag wurde angenommen.",
                            denied: "Der Antrag wurde abgelehnt.",
                            not_distinct: "Es konnte kein Einstimmiges Ergebnis festgelegt werden."
                        },
                        no_more_participant: "Es sind keine weiteren Teilnehmer anwesend.",
                        preliminary_remark: "Vorbemerkung"


                    },
                    headline:{
                        committee_overview: "Ausschussübersicht für ",
                        committee_create: "Ausschuss erstellen",
                        meeting_create: "Versammlung erstellen",
                        top_template_create: "TOP Template hinzufügen",
                        voting_template_assign: "Abstimmungsvorlage zuweisen",
                        voted: "Bereits abgestimmt",
                        number_of_votes: "Anzahl der Stimmen:",
                        not_voted: "Noch nicht abgestimmt"
                    },
                    input:{
                        committee:"Name des Auschuss*",
                        email: "E-Mail-Adresse",
                        password: "Passwort",
                        name: "Name",
                        order: "TOP-Nummer",
                        notes: "Notizen (intern)",
                        preliminary_remark: "Vorbemerkung",
                        motion:'Beschlussvorschlag',
                        file:"Datei hochladen",
                        meeting_title: "Versammlungsname",
                        meeting_type:"Versammlungsart",
                        meeting_place: "Versammlungsort",
                        date: "Datum",
                        time: "Uhrzeit",
                        administration: "Von der Verwaltung anwesend",
                        secretary: "Protokollführer",
                        comment: "Bemerkung (intern)",
                        options_limit: "Maximale Anzahl der auswählbaren Optionen",
                        voting_label: "Antwortmöglichkeit hinzufügen",
                        description: "Beschreibung",
                        voting_type: "Art der Abstimmung",
                        not_allowed_participants: "Ausgeschlossene Teilnehmer",
                        voting_title: "Abstimmungstitel",
                        voting_select: "Antwortmöglichkeit",
                        is_motion: "Ist diese Abstimmung ein Beschlussantrag?"
                    },
                    placeholder:{
                        pending: "Ausstehend",
                        email: "E-Mail-Adresse eingeben",
                        password: "Passwort",
                        name: "Name eingeben",
                        notes: "Notizen eingeben",
                        preliminary_remark: "Vorbemerkung eingeben",
                        motion: "Vorschlag für Beschlussantrag eingeben",
                        meeting_title: "Versammlungsname eingeben",
                        meeting_type:"Versammlungsart eingeben",
                        meeting_place: "Ort eingeben",
                        administration: "Verwaltung eingeben",
                        secretary: "Protokollführer eingeben",
                        comment: "Bemerkung eingeben",
                        options_limit: "Maximum eingeben",
                        text:"Text eingeben",
                        label:"Label eingeben",
                        voting_title: "Titel eingeben",
                        description: "Beschreibung eingeben",
                        voting_type: "Art der Abstimmung auswählen",
                        voting_select: "Antwortmöglichkeit eingeben",
                        choose: "Bitte auswählen"

                    },
                    error:{
                        options: "Bitte geben Sie Antwortmöglichkeiten an.",
                        motion_exsits: "Bitte geben Sie Antwortmöglichkeiten an."
                    },
                    description: {
                        agenda_item: "Hier sollte der TOP kurz und knapp beschrieben werden.",
                        order: "Durch die Nummer der TOP wird die Sortierung erstellt z.B. TOP 1 oder TOP 2.2",
                        notes: "Hier können Notizen für den internen Gebrauch eingetragen werden. Sie sind nicht öffentlich sichtbar.",
                        preliminary_remark: "Hier können Vorbemerkungen zum TOP eingetragen werden, welche im Protokoll ausgegeben werden.",
                        motion: "Hier kann der Vorschlag eines Beschlussantrags eingetragen werden.",
                        file: "Hier können Dateien für den internen Gebrauch hinterlegt werden.",
                        meeting_title: "Hier sollte die Versammlung kurz und knapp beschrieben werden.",
                        meeting_type: "Hier kann die Art der Versammlung gewählt werden.",
                        administration: "Hier können die von der Verwaltung anwesenden Personen eingetragen werden.",
                        comment: "Hier können Bemerkungen für den internen Gebrauch eingetragen werden.",
                        type_choose: "Bitte Typ auswählen...",
                        motion_exists: "Es wurde bereits ein Beschlussantrag für diesen TOP angelegt.",
                        is_motion: "Ist der Haken angewählt, wird der Beschlussvorschlag in die Beschreibung übernommen (Änderung ist möglich).",
                        voting_title: "Hier sollte die Abstimmung kurz und knapp beschrieben werden.",
                        description: "Hier kann der Gegenstand der Abstimmung beschrieben werden.",
                        voting_type:"Hier können die Auswahlmöglichkeiten für Teilnehmer eingestellt werden.",
                        not_allowed_participants: "Hier können einzelne Teilnehmer von der Abstimmung ausgeschlossen werden."

                    },
                    button:{
                        create: "Erstellen",
                        assign: "Zuweisen",
                        committee_create: "Ausschuss anlegen",
                        meeting_create: "Versammlung anlegen",
                        submit: "Absenden",
                        abort: "Abbrechen",
                        login: "Login",
                        result_show: "Ergebnisse anzeigen",
                        voting:{
                            create: "Abstimmung erstellen",
                            edit: "Abstimmung bearbeiten",
                            delete: 'Abstimmung löschen',
                            add: "Abstimmung hinzufügen"
                        },
                        agenda_item: {
                            create: "Tagesordnungspunkt anlegen",
                            show: "Tagesordnungspunkt anzeigen",
                            delete: "Tagesordnungspunkt löschen",
                            edit: "Tagesordnungspunkt bearbeiten",
                        },
                        voting_template: {
                            create: "Abstimmungsvorlage erstellen",
                            edit: "Abstimmungsvorlage bearbeiten",
                            remove: "Aus der Vorlage entfernen",
                            delete: "Abstimmungsvorlage löschen",
                            assign: "Bestehende Abstimmungsvorlage zuweisen"
                        }
                    },
                    confirm:{
                        delete_committee: 'Sind Sie sicher, dass sie das Element löschen wollen? Es werden alle zugehörigen Versammlungen gelöscht!',
                        delete_meeting: 'Sind Sie sicher, dass sie das Element löschen wollen?',
                        delete_top: 'Sind Sie sicher, dass sie das Element löschen wollen?',
                        voting_active: "Eine andere Abstimmung ist gerade aktiv, durch das starten einer neuen Abstimmung wird diese automatisch gestoppt. Wollen Sie die neue Abstimmung wirklich starten?",
                        delete_voting_template: 'Sind Sie sicher, dass sie die Vorlage löschen wollen? Sie wird ebenfalls aus allen anderen Vorlagen entfernt!'

                    }
                }
            }
        }
    });

export default i18n;