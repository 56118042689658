import {useSelector} from "react-redux";

const InputFeedback = ({name}) => {

    const errors = useSelector(state => state.errors);

    if (Object.entries(errors.errors).length !== 0) {
        if (errors.errors[name]) {
            const keyElemet = errors.errors[name];
            return (
                <div className="invalid-feedback">
                    {Object.entries(keyElemet).map(([key, value]) => {

                        if(typeof value === 'object' ) {
                            return value.map(val => {
                                return <p>{val.message}</p>
                            })

                        }

                        if (typeof value === 'string') {
                            return <p>{value}</p>
                        }

                        return null;

                    })}
                </div>
            )
        }
    }

    return null;



}

export default InputFeedback