const initState = {
    meetings: [],
    selected_meeting: null,
    isWaiting: false,
}

const meetingReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_MEETINGS":
            return {...state, meetings: action.payload.meetings, isWaiting: false};
        case "DELETE_MEETING":
            const filtered_meetings = state.meetings.filter((item) => item.id !== action.payload.id);
            return {...state, meetings: filtered_meetings, isWaiting: false};
        case "ADD_MEETING":
            return {
                ...state, meetings: state.meetings.concat(action.payload.meeting), isWaiting: false
            }
        case "MEETING_LOADING":
            return {
                ...state, isWaiting: true
            };
        case "MEETING_LOADING_ABORT":
            return {
                ...state, isWaiting: false
            };
        case "CLOSE_MEETING":
            let newMeetingState = state.meetings.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.meeting
                }
                return item
            });
            return {...state, selected_meeting: action.payload.meeting, meetings: newMeetingState};
        case "EDIT_MEETING":
            const newState = state.meetings.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.meeting
                }
                return item
            });

            return {
                ...state, meetings: newState, isWaiting: false
            };
        case "SHOW_MEETING":
            return {...state, selected_meeting: action.payload.meeting, isWaiting: false};
        case "CLEAR_MEETINGS":
            return initState;
        default:
            return state
    }
};


export default meetingReducer;