import {Button, Modal, ModalBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import SingeVoteForm from "../Forms/SingeVoteForm";
import {useDispatch, useSelector} from "react-redux";
import {getVoteByParticipantAndVoting, vote_for_participant} from "../../actions/voteAction";
import {useTranslation} from "react-i18next";


const SingleVoteModal = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {vote} = useSelector(state => state.vote);
    const [selected_option, setSelectedOption] = useState([]);


    useEffect(() => {
        if (props.voting) {
            dispatch(getVoteByParticipantAndVoting(props.voting.id, props.participant.id))
        }


// eslint-disable-next-line
    }, [dispatch])


    const handleSubmit = () => {
        dispatch(vote_for_participant(props.voting.id, props.participant.id, selected_option));
        props.toggle();
    }

    const renderForm = () => {

        if (!props.voting) {
            return "Kein Voting gestartet";
        }


        let notAllowed = [];

        if (props.voting.not_allowed_participants) {
            notAllowed = props.voting.not_allowed_participants.split(',')
        }

        if (notAllowed.includes(props.participant.id.toString())) {
            return "Der Teilnehmer ist von der {t('general.voting')} ausgeschlossen";
        }

        if (props.voting) {
            if (vote.length !== 0) {
                return "Der Teilnehmer hat bereits abgestimmt";
            } else {
                return <SingeVoteForm participant_id={props.participant.id} voting={props.voting} selected_option={selected_option} setSelectedOption={setSelectedOption}/>
            }
        } else {
            return "Kein Voting gestartet";
        }
    }

    const renderButton = () => {

        if (props.voting) {
            if (vote.length !== 0) {
                return (
                    <div className="processingButtons qrButton">
                        <Button color="lightgray" onClick={props.toggle}>Schließen</Button>
                    </div>
                )
            } else {
                return (
                    <div className="processingButtons qrButton">
                        <Button color="primary" onClick={handleSubmit}>Abstimmen</Button>
                        <Button color="lightgray" onClick={props.toggle}>Schließen</Button>
                    </div>
                )
            }
        } else {
            return (
                <div className="processingButtons qrButton">
                    <Button color="lightgray" onClick={props.toggle}>Schließen</Button>
                </div>
            )
        }
    }

    const renderHeadline = () => {
        if (props.voting) {
            return <h2 className="votingModalvotingTitle">{t('general.voting')}: {props.voting.title} für {props.participant.name}</h2>
        } else
            return null;
    }


    return (
        <Modal size="m" isOpen={true} backdrop="static" className="modal-dialog-centered">
            <ModalBody>
                <div>
                    {renderHeadline()}
                    {renderForm()}
                    {renderButton()}
                </div>
            </ModalBody>
        </Modal>);
};


export default SingleVoteModal;