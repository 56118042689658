const initState = {
    organisation: {},
    isWaiting: false,
}


const organisationReducer = (state = initState, action) => {
    switch (action.type) {
        case "ORGANISATION_LOADING":
            return {
                ...state, isWaiting: true
            };
        case "ORGANISATION_LOADING_ABORT":
            return {
                ...state, isWaiting: false
            };
        case "SHOW_ORGANISATION":
            return {...state, organisation:action.payload.organisation, isWaiting: false };
        case "CLEAR_ORGANISATION":
            return initState;
        default:
            return {...state};
    }
};


export default organisationReducer;