import {useSelector} from "react-redux";
import InputFeedback from "./InputFeedback";

const TextInput = ({name, label, placeholder, value, handleInputChange}) => {

    const errors = useSelector(state => state.errors);


    const renderClass = () => {
        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors[name]) {
                return "form-control form-control is-invalid"
            }

        }

        return "form-control"
    }


    const renderLabel = () => {
        if(label) {
            return (<label>{label}</label>)
        }
    }


    return (
        <div className="form-group">
            {renderLabel()}
            <input
                type="text"
                placeholder={placeholder}
                className={renderClass()}
                aria-invalid="true"
                value={value}
                name={name}
                onChange={handleInputChange}
            />

           <InputFeedback name={name} />

        </div>
    )


};

export default TextInput;