import React, {useEffect, useState} from "react";
import {Container, Row, Card} from "react-bootstrap";
import AgendaItem from "./AgendaItem";
import {Button} from "reactstrap";
import AddAgendaItem from "./Forms/AddAgendaItem";
import {useSelector, useDispatch} from "react-redux";
import {addAgendaItem, editAgendaItem, getAgendaItems} from "../actions/agendaItemAction";
import {
    addParticipant,
    getParticipantForDeputyVoteModalByMeeting,
    getParticipants,
    importParticipant
} from "../actions/participantAction";
import {closeMeeting, exportDocProtocol, exportProtocol, showMeeting} from "../actions/meetingAction";
import AddVoting from "./Forms/AddVoting";
import {addVoting, getVotingByMeeting} from "../actions/votingAction";
import {Link, useParams} from "react-router-dom";
import Pusher from "pusher-js";
import ParticipantModal from "./Modals/ParticipantModal";
import DeputyVoteModal from "./Modals/DeputyVoteModal";
import {getUserDeputys} from "../actions/userAction";
import AgendaItemTemplateImport from "./Modals/AgendaItemTemplateImport";
import {useTranslation} from "react-i18next";

const MeetingOverview = (props) => {

    const [form_data, setFormData] = useState({
        agenda_item_id: null,
        title: '',
        order: '',
        preliminary_remark: '',
        motion: '',
        notes: '',
        files: null,
    });

    const [participant_form_data, setParticipantFormData] = useState({
        name: '',
        email: '',
        party: '',
        list: null,
    });

    const [voting_form_data, setVotingFormData] = useState({
        agenda_item_id: '',
        title: '',
        description: '',
        type: '',
        options_limit: 0,
        is_motion: false,
        options: [],
        not_allowed_participants: [],
        motion: ''
    })
    const {t} = useTranslation();

    const {meetingId} = useParams()
    const [modal, setModal] = useState(false);
    const [participant_modal, setParticipantModal] = useState(false);
    const [voting_modal, setVotingModal] = useState(false);
    const {participants} = useSelector(state => state.participants);
    const meeting = useSelector(state => state.meetings.selected_meeting);
    const waiting_for_meeting = useSelector(state => state.meetings.isWaiting);
    const {agenda_items} = useSelector(state => state.agenda_items);
    const wating_for_agenda_items = useSelector(state => state.agenda_items.isWaiting);
    const wating_for_votings = useSelector(state => state.votings.isWaiting);
    const [show_result, setShowResult] = useState(false);
    const [deputy_vote, setDeputyVote] = useState(false);
    const [deputy_vote_enabled, setDeputyVoteEnabled] = useState(false);
    const [voting_template_import_modal, setVotingTemplateImportModal] = useState(false);
    const [trigger_reload, setTriggerReload] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserDeputys(meetingId));
        dispatch(getParticipantForDeputyVoteModalByMeeting(meetingId))
        if(participants) {
            let filterd_participants = participants.filter(participant => {
                if (participant.app_login === 0 && participant.present === 1){
                    return true;
                }
                return false;
            })
            if(filterd_participants.length > 0) {
                setDeputyVoteEnabled(true);
            } else {
                setDeputyVoteEnabled(false);
            }

        }
// eslint-disable-next-line
    },[participants])

    useEffect(() => {
        if(trigger_reload === true) {
            dispatch(getAgendaItems(meetingId))
            dispatch(getVotingByMeeting(meetingId))
            setTriggerReload(false);
        }
// eslint-disable-next-line
    },[trigger_reload])


    useEffect(() => {
        dispatch(getParticipants(meetingId))
        dispatch(showMeeting(meetingId))
        dispatch(getAgendaItems(meetingId))
        dispatch(getVotingByMeeting(meetingId))
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            'cluster': process.env.REACT_APP_PUSHER_CLUSTER
        });

        const channel = pusher.subscribe(`meeting-${meetingId}`)

        channel.bind('app.login', function (data) {
            dispatch(getParticipants(meetingId))
        });

        return function cleanup() {
            dispatch({type: "CLEAR_ERRORS"});
            dispatch({type: "CLEAR_AGENDA_ITEMS"});
            dispatch({type: "CLEAR_MEETINGS"});
            dispatch({type: "CLEAR_VOTINGS"});
            dispatch({type: "CLEAR_PARTICIPANTS"});
            dispatch({type: "ALERT_CLEAR"});
            channel.unbind()
            pusher.unsubscribe(channel)
            pusher.disconnect()
        }
    }, [dispatch, meetingId])

    useEffect(() => {
        if (!wating_for_agenda_items && modal) {
            setModal(false);
        }// eslint-disable-next-line
    }, [wating_for_agenda_items])


    useEffect(() => {
        if (!wating_for_votings && voting_modal) {
            setVotingModal(false);
        }// eslint-disable-next-line
    }, [wating_for_votings])

    const toggle = () => setModal(!modal);
    const toggleParticipantForm = () => {
        setParticipantModal(!participant_modal)
    };
    const toggleVotingForm = () => setVotingModal(!voting_modal);
    const toggleShowResult = () => setShowResult(!show_result);
    const toggelDeputyVote = () => setDeputyVote(!deputy_vote);
    const toggleAgendaItemTemplateImportModal = () => setVotingTemplateImportModal(!voting_template_import_modal);

    /**
     * Handler
     *
     */

    const inputChangeHandler = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({...prevState, [name]: value}));
    }

    const handleParticipantInputChange = (e) => {
        const {name, value} = e.target;
        setParticipantFormData(prevState => ({...prevState, [name]: value}));
    }


    const handleClose = () => {
        dispatch({type: "CLEAR_ERRORS"})
        dispatch({type:"ALERT_CLEAR"});
        dispatch({type: "AGENDA_ITEMS_LOADING_ABORT"})
        setFormData(
            {
                agenda_item_id: null,
                title: '',
                order: '',
                motion: '',
                preliminary_remark: '',
                notes: '',
                files: null,
            })
        setParticipantFormData({
            name: '',
            email: '',
            party: '',
            list: null,
        })
        setVotingFormData({
            agenda_item_id: '',
            title: '',
            description: '',
            type: '',
            options_limit: 0,
            is_motion: false,
            options: [],
            not_allowed_participants: [],
            motion: ''
        })
    }

    const downloadProtocolButtonHandler = () => {

        dispatch(exportProtocol(meetingId));
    }

    const downloadDocProtocolButtonHandler = () => {

        dispatch(exportDocProtocol(meetingId));
    }

    const editButtonHandler = (id) => {
        let agenda_item = agenda_items.filter(item => item.id === id);
        agenda_item = agenda_item[0];
        setFormData(
            {
                agenda_item_id: agenda_item.id,
                title: agenda_item.title,
                order: agenda_item.order,
                motion: agenda_item.motion,
                preliminary_remark: agenda_item.preliminary_remark,
                notes: agenda_item.notes,
                files: null,
            })
        toggle();

    }

    const renderResult = () => {
        if (show_result) {
            /*return (
                <Result
                    toggle={toggleShowResult}
                    modal={show_result}
                    handleClose={handleClose}
                />);*/
        } else {
            return null;
        }
    }

    const renderDeputyVote = () => {
        if (deputy_vote) {
            return (
                <DeputyVoteModal
                    toggle={toggelDeputyVote}
                    modal={deputy_vote}
                />
            );
        } else {
            return null;
        }
    }

    const renderAgendaItemModal = () => {
        if (modal) {
            return (
                <AddAgendaItem
                    modal={modal}
                    toggle={toggle}
                    values={form_data}
                    handleInputChange={inputChangeHandler}
                    handleFileChange={handleFileChange}
                    handleSubmit={handleSubmit}
                    handleClose={handleClose}
                />
            )
        } else {
            return null;
        }
    }

    const renderParticipantModal = () => {
        if (participant_modal) {
            return (
                <ParticipantModal
                    participants={participants}
                    modal={participant_modal}
                    toggle={toggleParticipantForm}
                    values={participant_form_data}
                    handleClose={handleClose}
                    handleInputChange={handleParticipantInputChange}
                    handleFileUpload={handleFileUpload}
                    handleFileChange={handleParticipantFileChange}
                    handleSubmit={handleParticipantSubmit}
                />
            );
        } else {
            return null;
        }
    }

    const renderVotingModal = () => {
        if (voting_modal) {
            return (
                <AddVoting
                    modal={voting_modal}
                    toggle={toggleVotingForm}
                    formData={voting_form_data}
                    setFormData={setVotingFormData}
                    handleClose={handleClose}
                    handleSubmit={handleVotingSubmit}
                    handleInputChange={inputChangeHandler}

                />

            );
        } else {
            return null;
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (form_data.agenda_item_id) {
            dispatch(editAgendaItem(form_data))
        } else {
            dispatch(addAgendaItem(form_data, meetingId))
        }
    }

    const handleVotingSubmit = (e) => {
        e.preventDefault();
        dispatch(addVoting(voting_form_data));
    }

    const handleFileChange = (name, file) => {
        setFormData(prevState => ({...prevState, [name]: file}));
    }

    const handleParticipantFileChange = (name, file) => {
        setParticipantFormData(prevState => ({...prevState, [name]: file}));
    }

    const handleFileUpload = () => {
        if (participant_form_data.participant_list) {
            dispatch(importParticipant(meetingId, participant_form_data.participant_list))
        }
    }

    const handleParticipantSubmit = (e) => {
        dispatch({type: "CLEAR_ERRORS"})
        dispatch({type:"ALERT_CLEAR"});
        dispatch(addParticipant(participant_form_data, meetingId));
        setParticipantFormData({
            name: '',
            email: '',
            party: '',
            list: null,
        })
    }

    const renderAgendaItems = () => {

        if (agenda_items.length) {
            return (
                agenda_items.map((item, key) => (
                    <Row key={key} className="agendaItem">
                        <div style={{width: '100%'}}>
                            <AgendaItem deputy_vote_enabled={deputy_vote_enabled} key={item.id} item={item} index={key} toggleDeputy={toggelDeputyVote} toggleVotingModal={toggleVotingForm} toggleShowResult={toggleShowResult} editButtonHandler={editButtonHandler} setFormData={setVotingFormData}/>
                        </div>
                    </Row>
                ))
            )
        } else {
            if (wating_for_agenda_items) {
                return (
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                )
            } else {
                return "Es wurden noch keine Tagesordnungspunkte angelegt";
            }

        }

    };

    const closeMeetingButton = () => {
        dispatch({type:"ALERT_CLEAR"});
        dispatch(closeMeeting(meetingId));
    }

    const renderAttendingParticipants = () => {

        if (participants.length === 0) {
            return "Noch keine Teilnehmer"
        }

        let present = participants.filter(item => item.present === 1).length;
        let deputed = participants.filter(item => item.deputyable_id).length;


            return (
               "Anwesend: " + (present+deputed )+" davon  vertreten: "+deputed + ", Gesamtzahl aller möglichen Teilnehmer: " +participants.length
            )
    }

    const renderMeeting = () => {

        if (meeting) {
            return (
                <>
                    <Row className="headRow">
                        <div>
                            <Link to={`/committee/${meeting.committee_id}`}><i className="dripicons-arrow-thin-left"/></Link>
                            <h1>{meeting.title}</h1>
                            <span className="attendingParticipants">
                                    {renderAttendingParticipants()}
                                </span>
                        </div>
                        <div className="multipleButtons topButton">
                            {meeting.closed ? "" : <Button color="danger" onClick={() => {
                                if (window.confirm('Sind Sie sicher, dass sie die Versammlung schließen wollen? Es wird keine Teilnahme oder Bearbeitung an dieser Versammlung mehr möglich sein.')) closeMeetingButton()
                            }}>Versammlung schließen</Button>}



                            <Button color="success" onClick={toggleParticipantForm}><i className="mdi mdi-account-group-outline" style={{marginRight: 5}}/>Teilnehmerliste</Button>
                        </div>
                    </Row>
                    <Row className="meetingOverviewTile">
                        <Card>
                            <Card.Body>
                                <Card.Title>{meeting.title}</Card.Title>
                                <Card.Text style={{whiteSpace: "pre-wrap"}}>
                                    {meeting.comment}<br/>
                                    <div>
                                        <span><strong>Datum</strong><br/>{meeting.date}</span>
                                        <span><strong>Uhrzeit</strong><br/>{meeting.time}</span>
                                        <span><strong>Versammlungsleiter</strong><br/>{meeting.user ? meeting.user.name : ""}</span>
                                        <span><strong>Protokollführer</strong><br/>{meeting.secretary}</span>
                                        <span><strong>Ort</strong><br/>{meeting.place}</span>
                                        <span><strong>Versammlungsart</strong><br/>{meeting.meeting_type}</span>
                                        <span><strong>Von der Verwaltung anwesend</strong><br/>{meeting.administration}</span>
                                        <span><strong>Teilnehmer</strong><br/>{participants.length}</span>

                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Row>
                    <Row>
                        <div className={"top-head"}>
                            <h2 style={{marginTop: 30}}>Tagesordnungspunkte</h2>
                            <div className="topButtons">
                                {meeting.closed ?
                                    <>
                                        <Button className={"mr-2"} color="success" onClick={downloadProtocolButtonHandler}><i className="mdi mdi-export" style={{marginRight: 5}}/> PDF Protokoll exportieren</Button>
                                        <Button color="success" onClick={downloadDocProtocolButtonHandler}><i className="mdi mdi-export" style={{marginRight: 5}}/>Word Protokoll exportieren</Button>
                                    </>
                                    : (
                                        <>
                                            <Button className={"mr-2"} color="success" onClick={toggle}><i className="mdi mdi-file-outline" style={{marginRight: 5}}/>{t('general.agenda_item')}
                                                {t('general.create')}</Button>
                                            <Button color="success" onClick={toggleAgendaItemTemplateImportModal}>Tagesordnungspunkt aus Vorlage hinzufügen</Button>
                                            {voting_template_import_modal && <AgendaItemTemplateImport forceReload={setTriggerReload} modal={voting_template_import_modal} toggle={toggleAgendaItemTemplateImportModal} />}
                                        </>
                                    )}
                            </div>
                        </div>
                    </Row>
                    {renderAgendaItems()}
                </>
            )
        } else {
            if (waiting_for_meeting) {
                return (
                    <div className="spinner">
                        <div className="bounce1"/>
                        <div className="bounce2"/>
                        <div className="bounce3"/>
                    </div>
                )
            }
        }

    }


    return (
        <Container fluid>
            {renderResult()}
            {renderAgendaItemModal()}
            {renderParticipantModal()}
            {renderDeputyVote()}
            {renderVotingModal()}
            {renderMeeting()}
        </Container>
    )
}

export default MeetingOverview;