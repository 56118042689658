import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";

import React, {useEffect, useState} from "react";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import SelectInput from "../Inputs/SelectInput";
import {useDispatch, useSelector} from "react-redux";
import {editVoting} from "../../actions/votingAction";
import {Container} from "react-bootstrap";
import {MultiSelect} from "react-multi-select-component";
import CheckboxInput from "../Inputs/CheckboxInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";

const EditVoting = ({toggle, modal, voting}) => {
    const {t} = useTranslation();
    const [voting_data, setVotingData] = useState({})

    const [option_form_data, setOptionFormData] = useState({
        label: '',
    })
    const [participant_options, setParticipantOptions] = useState([])
    const [show_form, setShowForm] = useState(false);
    const [motion_exists, setMotionExists] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const {message, type} = useSelector(state => state.alert);
    const errors = useSelector(state => state.errors);
    const {participants} = useSelector(state => state.participants);
    const {votings, isWaiting} = useSelector(state => state.votings);
    const {agenda_items} = useSelector(state => state.agenda_items);

    const dispatch = useDispatch();

    useEffect(() => {
        setVotingData(voting);
        let notAllowed = []
        if(voting.not_allowed_participants) {
            notAllowed = voting.not_allowed_participants.split(',')
        }

        let notAllowedArray = []
        let option_object = participants.map(particpant => {

            if(notAllowed.includes(particpant.id.toString())) {
                notAllowedArray.push({label: particpant.name, value: particpant.id})
            }
                return {label: particpant.name, value: particpant.id};

        })
        setVotingData(prevState => ({...prevState, not_allowed_participants:notAllowedArray}))
        setParticipantOptions(option_object);
        let selected_agenda_items = agenda_items.filter(item => item.id === voting.agenda_item_id);
        setVotingData(prevState => ({...prevState, motion: selected_agenda_items[0].motion}));
        for (let i = 0; i<votings.length; i++) {
            if(votings[i].agenda_item_id === voting.agenda_item_id) {
                if(votings[i].is_motion === 1 && votings[i].id !== voting.id) {
                        setMotionExists(true);
                        break;
                }
            }
        }

        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if(submitted && !isWaiting) {
            toggle();
        }
        setSubmitted(false);
// eslint-disable-next-line
    },[isWaiting, submitted])

    const handleInputChange = (e) => {
        const {name, value} = e.target;

        if (name === "type") {
            if ((voting_data.type === "select_default" ||voting_data.type === "select" ||voting_data.type === "multi_select") && value === "text") {
                setVotingData(prevState => ({...prevState, voting_options: []}));
            }
            if (voting_data.type === "text" && (value === "multi_select" || value === "select")) {
                setVotingData(prevState => ({...prevState, voting_options: []}));
            }
            if (value === 'read_and_confirm') {
                setVotingData(prevState => ({...prevState, voting_options: []}));
            }
        }

      if (name === "is_motion") {
            if (!voting_data.is_motion === true) {
                setVotingData(state => ({
                    ...state,
                    is_motion: !voting_data.is_motion,
                    description: voting_data.motion,
                    title: "Beschlussantrag"
                }))
            } else {
                setVotingData(state => ({...state, is_motion: !voting_data.is_motion}))
            }
        } else if (name === "type" && value === "select_default") {
            setVotingData(prevState => ({...prevState, voting_options: [{label: "Ja"},{label: "Nein"},{label: "Enthaltung"}]}))
            setVotingData(prevState => ({...prevState, [name]: "select"}));
        }
        else {
            setVotingData(prevState => ({...prevState, [name]: value}));
        }

    }

    const toggleOptionForm = () => {
        setShowForm(!show_form);
    }

    const handleOptionInutchange = (e) => {
        const {name, value} = e.target;
        setOptionFormData(prevState => ({...prevState, [name]: value}))
    }

    const addOption = () => {
        const tmp_data = voting_data.voting_options.concat(option_form_data);
      setVotingData(prevState => ({...prevState, voting_options: tmp_data}))
        setOptionFormData({
            label: '',
        })
    }

    const renderOptionLimitInput = () => {
        if (voting_data.type === "multi_select") {
            return (<SelectInput
                name="options_limit"
                value={voting_data.options_limit}
                label={t('input.options_limit')}
                placeholder={t('placeholder.options_limit')}
                handleInputChange={handleInputChange}
                options={{
                    '0': 'unbegrenzt',
                    '1': '1',
                    '2': '2',
                    '3': '3',
                    '4': '4',
                    '5': '5',
                    '6': '6',
                    '7': '7',
                    '8': '8',
                    '9': '9',
                    '10': '10',

                }}
            />)
        }

        return null;
    }

    const deleteOption = (element_index) => {
        const tmp_data = voting_data.voting_options.filter((option, key) => key !== element_index);
        setVotingData(prevState => ({...prevState, voting_options: tmp_data}))

    }
    const renderOptionTable = () => {
        if(!voting_data.voting_options) return null
        if (voting_data.voting_options.length) {

            return voting_data.voting_options.map((item, index) => {
                return (
                    <div>
                        <span>{item.label}</span>
                        <Button color="danger" onClick={() => deleteOption(index)}>
                            <i className="dripicons-trash"></i>
                        </Button>
                    </div>
                )
            })
        }

    }


    const handleSubmit = () => {
        dispatch({type: "CLEAR_ERRORS"});
        dispatch({type:"ALERT_CLEAR"});
        dispatch(editVoting(voting_data));
       setSubmitted(true)
    }

    const renderFormButton = () => {

        if (voting_data.type !== '') {
            if (voting_data.type === "text" && voting_data.voting_options.length > 0) {
                return <span style={{color: '#fa5c7c'}}>Für den Typ <strong>Text</strong> kann nur eine Option angegeben werden</span>
            } else if (voting_data.type === "read_and_confirm" && voting_data.voting_options.length > 0) {
                return <span style={{color: '#fa5c7c'}}>Für den Typ <strong>Bestätigen</strong> kann nur eine Option angegeben werden</span>
            } else {
                if (show_form) {
                    return <Button color="danger" onClick={toggleOptionForm}>{t('button.abort')}</Button>
                } else {
                    return <Button color="primary" onClick={toggleOptionForm}>{t('input.voting_label')}</Button>
                }
            }

        } else {
            return <Button color="primary" disabled={true}>{t('description.type_choose')}</Button>
        }

    }

    const change = (e) => {
        setVotingData(prevState => ({...prevState, not_allowed_participants: e}));
    }

    const renderOptionFeedback = () => {
        let feedBack_style = {
            color: "#fa5c7c",
            fontSize: "0.75rem",
            fontWeight: "400"

        }

        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors["options"]) {
                return (
                    <div>
                        <p style={feedBack_style}>{t('error.options')}</p>
                    </div>
                )
            }
        }
    }

    const renderForm = () => {
        if (show_form) {
            if (voting_data.type === 'select' || voting_data.type === 'multi_select') {

                return (
                    <>
                        <p>{t('input.voting_select')}</p>
                        <div>
                            <TextInput
                                placeholder={t('placeholder.voting_select')}
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                            />
                            <Button onClick={addOption} color="primary"><i
                                className="dripicons-checkmark"></i></Button>
                        </div>
                    </>
                )

            } else if (voting_data.type === 'read_and_confirm') {
                if (voting_data.voting_options.length <= 0) {
                    return (
                        <div>
                            <TextAreaInput
                                placeholder={t('placeholder.text')}
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                                rows={3}
                                cols={50}
                            />
                            <Button className="textarea-button" onClick={addOption} color="primary"><i className="dripicons-checkmark"></i></Button>
                        </div>
                    )
                }
            } else {
                if (voting_data.voting_options.length <= 0) {
                    return (
                        <div>
                            <TextInput
                                placeholder={t('placeholder.label')}
                                name="label"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.label}
                            />
                            <Button onClick={addOption} color="primary"><i className="dripicons-checkmark"></i></Button>
                        </div>
                    )
                }
            }
        }
    }



    return (
        <Modal size="lg" id="addVotingModal" isOpen={modal} backdrop="static" toggle={toggle} className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>{t('button.voting.edit')}</ModalHeader>
            <ModalBody>
                {message &&
                <Container fluid>
                    <Row>
                        <div className={`${type} alert-dismissible fade show`} role="alert">
                            <p>{message}</p>
                            <button type="button" className="btn-close" aria-label="Close"
                                    data-bs-dismiss="alert"
                                    onClick={() => dispatch({type: "ALERT_CLEAR"})}><i
                                className="mdi mdi-close"/></button>
                        </div>
                    </Row>
                </Container>
                }
                <Row>
                    <Col>
                        {!motion_exists ? <CheckboxInput
                            name="is_motion"
                            label={t('input.is_motion')}
                            handleInputChange={handleInputChange}
                            value=""
                            checked={voting_data.is_motion}
                        /> : <p><strong>{t('general.attention')}</strong><br/>{t('description.motion_exists')}</p>}
                        {!motion_exists ? <p>{t('description.is_motion')}</p> : ""}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="d-inline-block mb-1" >{t('input.voting_title')}*</p>
                        <FontAwesomeIcon data-tip data-for="registerTip" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                        <ReactTooltip className="custtooltip" id="registerTip" place="top" effect="solid">
                            {t('description.voting_title')}
                        </ReactTooltip>
                        <TextInput value={voting_data.title} name="title" handleInputChange={handleInputChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="d-inline-block mb-1" >{t('input.description')}*</p>
                        <FontAwesomeIcon data-tip data-for="description" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                        <ReactTooltip className="custtooltip" id="description" place="top" effect="solid">
                            {t('description.description')}
                        </ReactTooltip>
                        <TextAreaInput
                            name="description"
                            value={voting_data.description}
                            placeholder={t('placeholder.description')}
                            handleInputChange={handleInputChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="d-inline-block mb-1" >{t('general.voting_type')}*</p>
                        <FontAwesomeIcon data-tip data-for='choices' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                        <ReactTooltip className="custtooltip" id="choices" place="top" effect="solid">
                            {t('description.voting_type')}
                        </ReactTooltip>
                        <div className="no-label">
                        <SelectInput
                            name="type"
                            value={voting_data.type}
                            placeholder={t('placeholder.voting_type')}
                            handleInputChange={handleInputChange}
                            options={{
                                'select_default': 'Einfache Auswahl (Ja / Nein / Enthaltung)',
                                'select': 'Einfache Auswahl (Eigene Optionen)',
                                'multi_select': 'Mehrfach Auswahl',
                                'read_and_confirm': 'Bestätigung durch Teilnehmer',
                                'text': 'Texteingabe durch Teilnehmer',
                            }}
                        />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <p className="d-inline-block mb-1" >{t('general.not_allowed_participants')}</p>
                            <FontAwesomeIcon id="not_allowed" data-tip data-for="not_allowed" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="not_allowed" place="top" effect="solid">
                                {t('description.not_allowed_participants')}
                            </ReactTooltip>
                            <MultiSelect
                                options={participant_options}
                                value={voting_data.not_allowed_participants}
                                onChange={change}
                                labelledBy={"Select"}
                                overrideStrings={{
                                    "allItemsAreSelected": "Alle Teilnehmer wurden ausgewählt.",
                                    "clearSearch": "Suche leeren",
                                    "noOptions": "Keine Teilnehmer verfügbar",
                                    "search": "Suchen",
                                    "selectAll": "Alle auswählen",
                                    "selectSomeItems": "Auswählen..."
                                }}
                            />
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        {renderOptionLimitInput()}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {renderFormButton()}
                    </Col>
                </Row>
                <Row>
                    <Col id="voting_options">
                        {renderForm()}
                        {renderOptionTable()}
                        {renderOptionFeedback()}
                    </Col>
                </Row>

                <div className="processingButtons">
                    <Button color="lightgray" onClick={toggle}>{t('button.abort')}</Button>
                    <Button color="primary" onClick={() => handleSubmit()}>{t('button.create')}</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default EditVoting;