const initState = {
    type: null,
    errors: {},
}

export const errorReducer = (state = initState, action) => {

    switch (action.type) {
        case "SET_VALIDATION_ERRORS":
            return {
                ...state, type: action.payload.errors.error, errors: action.payload.errors.errors
            };
        case "SET_AUTHORIZATION_ERROR":
            return {
                ...state, type: "authorization", errors: action.payload.errors.errors
            };
        case "SET_GENERIC_ERRORS":
            return {
                ...state, type: "generic", errors: action.payload.errors
            };

        case "CLEAR_ERRORS":
            return initState;
        default:
            return state;
    }

};
