import React, {useEffect, useState} from "react";
import {Container, Col, Row, Table} from "react-bootstrap";
import {
    Button, DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    DropdownMenu,

} from "reactstrap";
import AddMeeting from "./Forms/AddMeeting";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {showCommittee} from "../actions/committeeAction";
import {addMeeting, deleteMeeting, editMeeting, getMeetings} from "../actions/meetingAction";
import {useTranslation} from "react-i18next";

const MeetingList = () => {

    const meeting_form_init_state = {
        title: '',
        meeting_type: '',
        date: '',
        time: '',
        place: '',
        administration: '',
        secretary: '',
        comment: '',
        meeting_id: '',
    }



    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {committeeId} = useParams()
    const committee = useSelector(state => state.committees.selected_committee);
    const {meetings, isWaiting} = useSelector(state => state.meetings);
    const [modal, setModal] = useState(false);

    const [meetingData, setMeetingData] = useState(meeting_form_init_state);

    useEffect(() => {
        dispatch(showCommittee(committeeId))
        dispatch(getMeetings(committeeId))
        return function cleanup() {
            dispatch({type: "CLEAR_ERRORS"});
            dispatch({type: "CLEAR_MEETINGS"});
        }

    }, [dispatch, committeeId]);

    useEffect(() => {
        if (modal && !isWaiting) {
            setModal(false);
        }// eslint-disable-next-line
    }, [isWaiting])


    const toggle = () => setModal(!modal);

    const inputChangeHandler = (e) => {
        const {name, value} = e.target;
        setMeetingData(prevState => ({...prevState, [name]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({type: "CLEAR_ERRORS"});
        if (meetingData.meeting_id) {
            dispatch(editMeeting(meetingData))
        } else {
            dispatch(addMeeting(meetingData, committee.id));
        }
    }

    const handleClose = () => {
        setMeetingData(meeting_form_init_state)
        dispatch({type: "CLEAR_ERRORS"});
        dispatch({type: "MEETING_LOADING_ABORT"})
    }

    const deleteButtonHandler = (e) => {
        dispatch(deleteMeeting(e));
    }

    const editButtonHandler = (id) => {
        let meeting = meetings.filter(item => item.id === id);
        meeting = meeting[0];
        setMeetingData({
            meeting_id: id,
            title: meeting.title,
            administration: meeting.administration,
            meeting_type: meeting.meeting_type,
            date: meeting.date,
            time: meeting.time,
            place: meeting.place,
            secretary: meeting.secretary,
            comment: meeting.comment,
            weg_id: meeting.weg_id
        })
        toggle()
    }


    const renderMeetings = () => {
        if (meetings.length) {
            return (
                <Table>
                    <thead>
                    <tr>
                        <th>{t('general.name')}</th>
                        <th className="tableColumnMinSize">ID</th>
                        <th>{t('general.meeting_type')}</th>
                        <th>{t('general.appointment')}</th>
                        <th>{t('general.place')}</th>
                        <th className="tableColumnMinSize">{t('general.action')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {meetings.map(meeting => (
                        <tr key={meeting.id}  className="meeting-list">
                            <td>
                                <Link to={`/meeting/${meeting.id}`}>{meeting.title}</Link>
                            </td>
                            <td>{meeting.id}</td>
                            <td>{meeting.meeting_type}</td>
                            <td className="flex-td"><i className="mdi mdi-calendar-today"></i>{new Date(meeting.date).toLocaleDateString()}, {meeting.time} Uhr</td>
                            <td>{meeting.place}</td>
                            <td>
                                {meeting.closed ? <span className="meetingClosed">{t('general.closed')}</span>: (
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle caret color="primary">
                                            {t('general.action')}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => editButtonHandler(meeting.id)}>{t('general.edit')}</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                if (window.confirm(t('confirm.delete_meeting'))) deleteButtonHandler(meeting.id)
                                            }}>Löschen</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                ) }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            );
        } else {
            if (!isWaiting) {
                return t('general.no_meetings')
            } else {
                return (
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                )
            }

        }

    }

    const renderMeetingModal = () => {
        if (modal) {
            return (
                <AddMeeting
                    onClosed={handleClose}
                    modal={modal}
                    toggle={toggle}
                    handleInputChange={inputChangeHandler}
                    handleSubmit={handleSubmit}
                    values={meetingData}
                />)
        } else {
            return null;
        }
    }

    return (
        <Container fluid>
            <Row className="headRow">
                <div>
                    <Link to={"/"}><i className="dripicons-arrow-thin-left"></i></Link>
                    <h1>{committee.name}</h1>
                </div>
                <div className="topButton">
                    <Button color="success" onClick={toggle}>{t('button.meeting_create')}</Button>
                    {renderMeetingModal()}
                </div>
            </Row>
            <Row className="overviewTable">
                <Col>
                    <h2>{t('general.meetings')}</h2>
                </Col>
                <Col>
                    {renderMeetings()}
                </Col>
            </Row>
        </Container>
    )
}

export default MeetingList;