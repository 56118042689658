import Topbar from "./components/Topbar";
import {BrowserRouter} from 'react-router-dom';
import Routing from "./components/Routing";

import './assets/scss/Saas.scss';
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getUser} from "./actions/userAction";
import {Container, Row} from "react-bootstrap";
import {isLogin} from "./_services/userService";

const App = () =>  {

    const {message, type} = useSelector(state => state.alert)
    const {user, isLoggedIn} = useSelector(state => state.user)
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLogin() && !getUser()){
            dispatch(getUser())
        }

        if(isLogin() && !isLoggedIn && Object.keys(user).length === 0 && user.constructor === Object ){
            dispatch(getUser())
        }

        if(isLoggedIn && Object.keys(user).length === 0 && user.constructor === Object && isLogin()) {
            dispatch(getUser())
        }

    },[isLoggedIn])

    return (
                <div className="app">
                    <div className="wrapper">
                        <div className="content-page">
                            <div className="content">
                                <BrowserRouter>
                                    <Topbar/>
                                    {message &&
                                    <Container fluid>
                                        <Row>
                                            <div className={`${type} alert-dismissible fade show`} role="alert">
                                                <p>{message}</p>
                                                <button type="button" className="btn-close" aria-label="Close"
                                                        data-bs-dismiss="alert"
                                                        onClick={() => dispatch({type: "ALERT_CLEAR"})}><i
                                                    className="mdi mdi-close"/></button>
                                            </div>
                                        </Row>
                                    </Container>
                                    }
                                    <Routing/>
                                </BrowserRouter>
                            </div>
                        </div>
                    </div>
                </div>
    );
}

export default App;
