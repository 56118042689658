const initState = {
    vote: [],
    votes:[],
    isWaiting: false
};

const voteReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_VOTE":
            return {
                ...state, vote: action.payload.vote, isWaiting: false
            }
        case "FETCH_VOTE_BY_MEETING":
            return {
                ...state, votes: action.payload.votes, isWaiting: false
            }
        case "VOTE_LOADING":
            return {
                ...state, isWaiting: true
            };
        case "VOTE_LOADING_ABORT":
            return {
                ...state, isWaiting: false
            };
        case "CLEAR_VOTE":
            return initState;
        default:
            return state
    }
};


export default voteReducer;