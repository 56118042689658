const initState = {
    voting_templates: [],
    voting_template_waiting: false
}


const votingTemplateReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_VOTING_TEMPLATES":
            return {...state, voting_templates: action.payload.voting_templates, voting_template_waiting: false}
        case "ADD_VOTING_TEMPLATE":
            return {
                ...state, voting_templates: state.voting_templates.concat(action.payload.voting_template), voting_template_waiting: false
            }
        case "DELETE_VOTING_TEMPLATE":
            return {
                ...state,
                voting_templates: state.voting_templates.filter((item) => item.id !== action.payload.id),
                voting_template_waiting: false
            };
        case "EDIT_VOTING_TEMPLATE":
            const newState = state.voting_templates.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.voting_template
                }
                return item
            });

            return {
                ...state, voting_templates: newState, voting_template_waiting: false
            };

        case "VOTING_TEMPLATES_LOADING":
            return {
                ...state, voting_template_waiting: true
            };
        case "VOTING_TEMPLATES_LOADING_ABORT":
            return {
                ...state, voting_template_waiting: false
            };
        case "CLEAR_VOTING_TEMPLATES":
            return initState;
        default:
            return state
    }
};


export default votingTemplateReducer;