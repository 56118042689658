const initState = {
    type: 'alert alert-info',
    message: ""
}

export const alertReducer = (state = initState, action) => {
    switch (action.type) {
        case "ALERT_SUCCESS":
            return {
                type: 'alert alert-success',
                message: action.payload.message
            };
        case "ALERT_ERROR":
            return {
                type: 'alert alert-danger',
                message: action.payload.message
            };
        case "ALERT_INFO":
            return {
                type: 'alert alert-info',
                message: action.payload.message
            };
        case "ALERT_CLEAR":
            return initState;
        default:
            return state;
    }
}