import CheckboxInput from "../Inputs/CheckboxInput";
import TextInput from "../Inputs/TextInput";
import React, {useState} from "react";
import SelectInput from "../Inputs/SelectInput";
import {useTranslation} from "react-i18next";

const SingleVoteForm = ({voting, selected_option, setSelectedOption}) => {
    const {t} = useTranslation();
    const [check_count, setCheckCount] = useState([]);

    const multiSelectChangeHandler = (e) => {
        const {name, value} = e.target;

        if(selected_option.filter(item => item.voting_option_id === value).length !== 0) {
          let new_option = selected_option.filter(item => item.voting_option_id !== value)
          setSelectedOption(new_option);
          setCheckCount(check_count.filter(item => item !== parseInt(value)));
        } else {
            let new_select_options = selected_option;
            new_select_options.push({voting_option_id: value, value: name})
            setSelectedOption(new_select_options)
            setCheckCount(check_count.concat(parseInt(value)));
        }
    }

    const textInputChangelHandler = (e) => {
        const {name, value} = e.target;
        if(value.length === 0) {
            setSelectedOption([])
        } else {
            let new_voting_option = [{voting_option_id: name, value: value}]
            setSelectedOption(new_voting_option)
        }

    }

    const selectInputChangeHandler = (e) => {
        const {value} = e.target;

        if(value.length === 0) {
            setSelectedOption([])
        } else {
            let new_voting_option = [{voting_option_id: value, value: value}]
            setSelectedOption(new_voting_option)
        }
    }

    const checkBoxInputHandler = (e) => {
        const {name} = e.target;
        if(selected_option.length !== 0) {
            setSelectedOption([])
        }
        else {
            let new_voting_option = [{voting_option_id: name, value: true}]
            setSelectedOption(new_voting_option)
        }
    }

    const renderOptions = () => {
        if(voting.type === 'multi_select')
        {

            return voting.voting_options.map(item => {
                if(check_count.length === voting.options_limit && voting.options_limit !== 0) {
                    if(!check_count.includes(item.id)) {
                        return (
                            <CheckboxInput
                                label={item.label}
                                value={item.id}
                                name={item.label}
                                handleInputChange={multiSelectChangeHandler}
                                disabled={true}
                            />
                        )
                    }
                }

                return (
                    <CheckboxInput
                        label={item.label}
                        value={item.id}
                        name={item.label}
                        handleInputChange={multiSelectChangeHandler}
                     />
                );
            })

        }

        else if(voting.type ==='select') {

            var option_object = {};
            for (let i = 0; i < voting.voting_options.length; i++) {
                option_object[voting.voting_options[i].id] = voting.voting_options[i].label;
            }

            var selected_value = ""
            if(selected_option.length >= 1) {
                selected_value = selected_option[0].value;
            }
            return (
                <SelectInput placeholder={t('placeholder.choose')} name={voting.id} label={voting.label} value={selected_value} handleInputChange={selectInputChangeHandler} options={option_object} />
            )
        }
        else if (voting.type ==='read_and_confirm') {
            return (
                <>
                    <span style={{display: 'block', marginBottom: 10}}>{voting.voting_options[0].label}</span>
                    <CheckboxInput label="Akzeptieren" handleInputChange={checkBoxInputHandler} name={voting.voting_options[0].id} value={true}/>
                </>
            )
        } else if (voting.type === 'text') {
            return (
                <TextInput label={voting.voting_options[0].label} name={voting.voting_options[0].id}  handleInputChange={textInputChangelHandler} />
            )
        }

    }
    return (
        <>
            <p>{voting.description}</p>
            <p style={{color: '#fa5c7c'}}>
                {voting.options_limit ? `Maximal ${voting.options_limit} Optionen wählbar`: ""}
            </p>
            {renderOptions()}
        </>

    )

}


export default SingleVoteForm;