import {Button, Modal, ModalBody} from "reactstrap";
import React from "react";
import QRCode from "react-qr-code";

const QrModal = (props) => {

    return (
    <Modal size="sm" isOpen={true} backdrop="static" className="modal-dialog-centered">
        <ModalBody>
            <QRCode value={props.value} size="260" />
            <div className="processingButtons qrButton">
                <Button color="lightgray" onClick={props.toggle}>Schließen</Button>
            </div>
        </ModalBody>
    </Modal>);
};


export default QrModal;