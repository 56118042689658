import React from "react";
import Chart from 'react-apexcharts';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ResultChart = ({voting}) => {
    const {t} = useTranslation();

    const {result, voted_count} = useSelector(state => state.result);


    const apexDonutOpts = {
        chart: {
            height: 320,
            type: 'pie',
        },
        labels: [],
        colors: ['#727cf5', '#6c757d', '#0acf97', '#fa5c7c', '#e3eaef'],
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
            offsetY: -10,
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        height: 240,
                    },
                    legend: {
                        show: false,
                    },
                },
            },
        ],
    };
    const apexDonutData = [];

    const renderChart = () => {

        if (voted_count === 0){
            return "Bisher wurden keine Stimmen abgegeben.";
        }

        if (result) {
            if (voting.type === 'select' || voting.type === 'multi_select' || voting.type === 'read_and_confirm') {
                for (let item in result) {
                    if (item === 'final_result_identifier') continue;
                    apexDonutOpts.labels.push(result[item].label);
                    apexDonutData.push(result[item].votes)
                }


                let text = "";
                if (voting.type === 'select' || voting.type === 'multi_select') {
                    if (result['final_result_identifier'] === 1) {
                        text = <strong>{t('general.result.accepted')}</strong>;
                    }

                    if (result['final_result_identifier'] === 2) {
                        text = <strong>{t('general.result.denied')}</strong>;
                    }

                    if (result['final_result_identifier'] === 3) {
                        text = <strong>{t('general.result.not_distinct')}</strong>;
                    }

                }
                return (
                    <>
                        {text !== "" ? <p className="chartResultBox">{text}</p> : null}
                        <Chart options={apexDonutOpts} series={apexDonutData} type="pie" height={500} className="apex-charts"/>
                    </>)
            }

            if (voting.type === 'text') {
                return (
                    <table className="table table-responsive resultTable">
                        <thead className="thNoBorder">
                        <th>{t('general.participant')}</th>
                        <th>{t('general.intake')}</th>
                        </thead>
                        <tbody>
                        {result.map(item => {

                            return (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item.value}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                )
            }
        } else {
            return t('general.no_votes');
        }
    }

    return renderChart()
}

export default ResultChart;



