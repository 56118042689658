import {Button, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import TextAreaInput from "../Inputs/TextAreaInput";
import {useTranslation} from "react-i18next";

const TemplateModal = ({modal, toggle, values, setValues, handleSubmit, handleClose}) => {

    const {t} = useTranslation();
    useEffect(() => {
        return function cleanUp() {
            handleClose();
        }// eslint-disable-next-line
    },[])

    const handleInputChange = (e) => {
        const {name, value} = e.target
        setValues(prevState => ({...prevState, [name]: value}));

    }


    return (
        <Modal size="xl" isOpen={modal} backdrop="static" className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>Template erstellen</ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col sm="12">
                            <p className="d-inline-block mb-1" >{t('input.name')}*</p>
                            <FontAwesomeIcon data-tip data-for='top-name' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="top-name" place="top" effect="solid">
                                Hier sollte der TOP kurz und knapp beschrieben werden.
                            </ReactTooltip>
                            <TextAreaInput name="title" value={values.title} label={false} placeholder="Name eingeben" handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >Notizen (Intern)</p>
                            <FontAwesomeIcon data-tip data-for='note' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="note" place="top" effect="solid">
                                Hier können Notizen für den internen Gebrauch eingetragen werden. Sie sind nicht öffentlich sichtbar.
                            </ReactTooltip>
                            <TextAreaInput name="notes" value={values.notes} label={false} placeholder="Notizen eingeben"  handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >Vorbemerkung</p>
                            <FontAwesomeIcon data-tip data-for='remark' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="remark" place="top" effect="solid">
                                Hier können Vorbemerkungen zum TOP eingetragen werden, welche im Protokoll ausgegeben werden.
                            </ReactTooltip>
                            <TextAreaInput name="preliminary_remark" value={values.preliminary_remark} label={false} placeholder="Vorbemerkung eingeben" handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >Beschussvorschlag</p>
                            <FontAwesomeIcon data-tip data-for='resolution' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="resolution" place="top" effect="solid">
                                Hier kann der Vorschlag eines Beschlussantrags eingetragen werden.
                            </ReactTooltip>
                            <TextAreaInput name="motion" value={values.motion} label={false} placeholder="Vorschlag für Beschlussantrag eingeben" handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <div className="processingButtons">
                        <Button color="lightgray" onClick={toggle}>{t('button.abort')}</Button>
                        <Button onClick={handleSubmit} color="primary">Erstellen</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default TemplateModal;