import axios from "axios";
import fileDownload from 'js-file-download';
import {errorBuilder} from "./errorAction";
import {getToken, getUser} from "../_services/userService";

export const getMeetings = (committee_id) => async (dispatch) => {
    dispatch({type:"MEETING_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/committee/${committee_id}/meetings`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "FETCH_MEETINGS",
                payload: {
                    meetings: response.data.data
                }
            });
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

};

export const showMeeting = (id) => async (dispatch) => {
    dispatch({type:"MEETING_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${id}`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "SHOW_MEETING",
                payload: {
                    meeting: response.data.data
                }
            });

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

}

export const deleteMeeting = (id) => (dispatch) => {
    dispatch({type:"MEETING_LOADING"})
    axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${id}`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "DELETE_MEETING",
                payload: {
                    id: id
                }
            })
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
};

export const addMeeting = (meetingData, committeeId) => async (dispatch) => {
    dispatch({type:"MEETING_LOADING"})
    let user = getUser();
    if (user===null) {
        dispatch({type:"MEETING_LOADING_ABORT"})
        dispatch({type: "SET_GENERIC_ERRORS",
            payload: {
                errors:"Kein Benutzer"
            }})

    } else {
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/meeting`, {
            title: meetingData.title,
            meeting_type: meetingData.meeting_type,
            date: meetingData.date,
            time: meetingData.time,
            user_id:user.id,
            meeting_leader: meetingData.meeting_leader,
            secretary: meetingData.secretary,
            administration: meetingData.administration,
            comment: meetingData.comment,
            place: meetingData.place,
            committee_id: committeeId
        }, {
            headers: {
                'Authorization': "Bearer " + getToken()
            }
        }).then(response => {

            dispatch({
                type: "ADD_MEETING",
                payload: {
                    meeting: response.data.data
                }
            })

        }).catch(error => {
            dispatch(errorBuilder(error));

        });
    }
};

export const closeMeeting = (meeting_id) => async (dispatch) => {
    dispatch({type:"MEETING_LOADING"})
    let today = new Date();
    let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date+' '+time;
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}`, {
        closed: dateTime,
    },{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then((response) => {
            dispatch({
                type: "CLOSE_MEETING",
                payload: {
                    meeting: response.data.data,
                    id: meeting_id
                }
            })
        })
        .catch((error) => {
            dispatch(errorBuilder(error));
        })

};

export const exportProtocol = (meeting_id) => async(dispatch) => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/protocol?type=pdf`, {
        responseType: 'blob',
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(res => {
        let date = new Date().toLocaleDateString('de-DE',{ day: '2-digit', year: 'numeric', month: '2-digit' })
        fileDownload(res.data, date+"_Protokoll-Versammlung"+meeting_id+".pdf");

    }).catch((error) => {
        dispatch(errorBuilder(error));
    });
}

export const exportParticipantList = (meeting_id) => async(dispatch) => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/participants/export`, {
        responseType: 'blob',
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(res => {
        let date = new Date().toLocaleDateString('de-DE',{ day: '2-digit', year: 'numeric', month: '2-digit' })
        fileDownload(res.data, date+"Teilnehmerliste-Versammlung"+meeting_id+".pdf");

    }).catch((error) => {
        dispatch(errorBuilder(error));
    });
}

export const exportDocProtocol = (meeting_id) => async(dispatch) => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/protocol?type=doc`, {
        responseType: 'blob',
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(res => {
        let date = new Date().toLocaleDateString('de-DE',{ day: '2-digit', year: 'numeric', month: '2-digit' })
        fileDownload(res.data, date+"_Protokoll-Versammlung"+meeting_id+".docx");

    }).catch((error) => {
        dispatch(errorBuilder(error));
    });
}

export const editMeeting = (meetingData) => async (dispatch) => {
    dispatch({type:"MEETING_LOADING"})
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meetingData.meeting_id}`, {
        title: meetingData.title,
        meeting_type: meetingData.meeting_type,
        date: meetingData.date,
        time: meetingData.time,
        meeting_leader: meetingData.meeting_leader,
        secretary: meetingData.secretary,
        administration: meetingData.administration,
        comment: meetingData.comment,
        place: meetingData.place,
    },{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then((response) => {
            dispatch({
                type: "EDIT_MEETING",
                payload: {
                    meeting: response.data.data,
                    id: meetingData.meeting_id
                }
            })
        })
        .catch((error) => {
            dispatch(errorBuilder(error));
        })

};

export const importAgendaFromTemplate = (form_data) => async (dispatch) => {
    dispatch({type:"MEETING_LOADING"})
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${form_data.meeting_id}/agenda/import`, form_data, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({type:"MEETING_LOADING_ABORT"})
        })
        .catch((error) => {
            dispatch(errorBuilder(error));
        })

}
