const initState = {
    committees: [],
    selected_committee: {
        name: ''
    },
    isWaiting: false,
}


const committeeReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_COMMITTEES":
            return {...state, committees: action.payload.committees, isWaiting: false};
        case "DELETE_COMMITTEE":
            const filterdWeg = state.committees.filter((item) => item.id !== action.payload.id)
            return {...state, committees: filterdWeg, isWaiting: false};
        case "ADD_COMMITTEE":
            return {
                ...state, committees: state.committees.concat(action.payload.committee), isWaiting: false
            };
        case "COMMITTEE_LOADING":
            return {
                ...state, isWaiting: true
            };
        case "COMMITTEE_LOADING_ABORT":
            return {
                ...state, isWaiting: false
            };
        case "EDIT_COMMITTEE":
            const newState = state.committees.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.committee
                }
                return item
            });
            return {...state, committees:newState, isWaiting: false};
        case "SHOW_COMMITTEE":
            return {...state, selected_committee:action.payload.committee, isWaiting: false };
        case "CLEAR_COMMITTEES":
            return initState;
        default:
            return {...state};
    }
};


export default committeeReducer;