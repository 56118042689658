import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import SelectInput from "../Inputs/SelectInput";
import {Button, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import {Col, Row} from "react-bootstrap";
import CheckboxInput from "../Inputs/CheckboxInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";

const AddVotingTemplate = ({form_data, setFormData, handleClose, modal, toggle, handleSubmit}) => {
    const {t} = useTranslation();
    const errors = useSelector(state => state.errors);
    const [show_form, setShowForm] = useState(false);
    const [option_form_data, setOptionFormData] = useState({
        value: '',
    })

    useEffect(() => {
        return function cleanUp() {
            handleClose();
        }// eslint-disable-next-line
    },[])


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === "type") {
            if ((form_data.type === "select_default" || form_data.type === "select" || form_data.type === "multi_select") && value === "text") {
                setFormData(prevState => ({...prevState, voting_option_templates: []}));
            }
            if (form_data.type === "text" && (value === "multi_select" || value === "select")) {
                setFormData(prevState => ({...prevState, voting_option_templates: []}));
            }
            if (value === 'read_and_confirm') {
                setFormData(prevState => ({...prevState, voting_option_templates: []}));
            }
        }

   if (name === "is_motion") {
            if (!form_data.is_motion === true) {
                if(form_data.motion && form_data.motion.length !==0) {
                    setFormData(state => ({
                        ...state,
                        is_motion: !form_data.is_motion,
                        description: form_data.motion,
                        title: "Beschlussantrag"
                    }))
                } else {
                    setFormData(state => ({
                        ...state,
                        is_motion: !form_data.is_motion,
                        title: "Beschlussantrag"
                    }))
                }
            } else {
                setFormData(state => ({...state, is_motion: !form_data.is_motion}))
            }
        } else if (name === "type" && value === "select_default") {
            setFormData(prevState => ({...prevState, voting_option_templates: [{value: "Ja"},{value: "Nein"},{value: "Enthaltung"}]}))
            setFormData(prevState => ({...prevState, [name]: "select"}));
        }
        else {
            setFormData(prevState => ({...prevState, [name]: value}));
        }

    }

    const handleOptionInutchange = (e) => {
        const {name, value} = e.target;
        setOptionFormData(prevState => ({...prevState, [name]: value}))
    }

    const toggleOptionForm = () => {
        setShowForm(!show_form);
    }

    const addOption = () => {
        const tmp_data = form_data.voting_option_templates.concat(option_form_data);
        setFormData(prevState => ({...prevState, voting_option_templates: tmp_data}))
        setOptionFormData({
            value: '',
        })
    }

    const renderOptionLimitInput = () => {
        if (form_data.type === "multi_select") {
            return (<SelectInput
                name="options_limit"
                value={form_data.options_limit}
                label={t('input.options_limit')}
                placeholder={t('placeholder.options_limit')}
                handleInputChange={handleInputChange}
                options={{
                    '0': 'unbegrenzt',
                    '1': '1',
                    '2': '2',
                    '3': '3',
                    '4': '4',
                    '5': '5',
                    '6': '6',
                    '7': '7',
                    '8': '8',
                    '9': '9',
                    '10': '10',

                }}
            />)
        }

        return null;
    }

    const deleteOption = (element_index) => {
        const tmp_data = form_data.voting_option_templates.filter((option, key) => key !== element_index);
        setFormData(prevState => ({...prevState, voting_option_templates: tmp_data}))

    }

    const renderOptionTable = () => {
        if (form_data.voting_option_templates.length) {

            return form_data.voting_option_templates.map((item, index) => {
                return (
                    <div>
                        <span>{item.value}</span>
                        <Button color="danger" onClick={() => deleteOption(index)}>
                            <i className="dripicons-trash"></i>
                        </Button>
                    </div>
                )
            })
        }

    }

    const renderFormButton = () => {

        if (form_data.type !== '') {
            if (form_data.type === "text" && form_data.voting_option_templates.length > 0) {
                return <span style={{color: '#fa5c7c'}}>Für den Typ <strong>Text</strong> kann nur eine Option angegeben werden</span>
            } else if (form_data.type === "read_and_confirm" && form_data.voting_option_templates.length > 0) {
                return <span style={{color: '#fa5c7c'}}>Für den Typ <strong>Bestätigen</strong> kann nur eine Option angegeben werden</span>
            } else {
                if (show_form) {
                    return <Button color="danger" onClick={toggleOptionForm}>{t('button.abort')}</Button>
                } else {
                    return <Button color="primary" onClick={toggleOptionForm}>Antwortmöglichkeit hinzufügen</Button>
                }
            }

        } else {
            return <Button color="primary" disabled={true}>Bitte Typ auswählen...</Button>
        }

    }

    const renderForm = () => {
        if (show_form) {
            if (form_data.type === 'select' || form_data.type === 'multi_select') {

                return (
                    <>
                        <p>{t('input.voting_select')}</p>
                        <div>
                            <TextInput
                                placeholder={t('placeholder.voting_select')}
                                name="value"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.value}
                            />
                            <Button onClick={addOption} color="primary"><i
                                className="dripicons-checkmark"></i></Button>
                        </div>
                    </>
                )

            } else if (form_data.type === 'read_and_confirm') {
                if (form_data.voting_option_templates.length <= 0) {
                    return (
                        <div>
                            <TextAreaInput
                                placeholder={t('placeholder.text')}
                                name="value"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.value}
                                rows={3}
                                cols={50}
                            />
                            <Button className="textarea-button" onClick={addOption} color="primary"><i className="dripicons-checkmark"></i></Button>
                        </div>
                    )
                }
            } else {
                if (form_data.voting_option_templates.length <= 0) {
                    return (
                        <div>
                            <TextInput
                                placeholder={t('placeholder.label')}
                                name="value"
                                handleInputChange={handleOptionInutchange}
                                value={option_form_data.value}
                            />
                            <Button onClick={addOption} color="primary"><i className="dripicons-checkmark"></i></Button>
                        </div>
                    )
                }
            }
        }
    }

    const renderOptionFeedback = () => {
        let feedBack_style = {
            color: "#fa5c7c",
            fontSize: "0.75rem",
            fontWeight: "400"

        }

        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors["voting_option_templates"]) {
                return (
                    <div>
                        <p style={feedBack_style}>{t('error.options')}</p>
                    </div>
                )
            }
        }
    }



    return (
        <Modal size="lg" id="addVotingModal" isOpen={modal} backdrop="static" toggle={toggle} className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>{t('button.voting.create')}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <CheckboxInput
                                name="is_motion"
                                label={t('input.is_motion')}
                                handleInputChange={handleInputChange}
                                value=""
                                checked={form_data.is_motion}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.voting_title')}*</p>
                            <FontAwesomeIcon data-tip data-for="registerTip" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="registerTip" place="top" effect="solid">
                                {t('description.voting_title')}
                            </ReactTooltip>

                            <TextInput
                                name="title"
                                value={form_data.title}
                                placeholder={t('placeholder.voting_title')}
                                handleInputChange={handleInputChange}
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.description')}*</p>
                            <FontAwesomeIcon data-tip data-for="description" className="ml-1 custtooltip-trigger" icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="description" place="top" effect="solid">
                                {t('description.description')}
                            </ReactTooltip>
                            <TextAreaInput
                                name="description"
                                value={form_data.description}
                                placeholder={t('placeholder.description')}
                                handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('general.voting_type')}*</p>
                            <FontAwesomeIcon data-tip data-for='choices' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="choices" place="top" effect="solid">
                                {t('description.voting_type')}
                            </ReactTooltip>
                            <div className="no-label">
                                <SelectInput
                                    name="type"
                                    value={form_data.type}
                                    placeholder={t('placeholder.voting_type')}
                                    handleInputChange={handleInputChange}
                                    options={{
                                        'select_default': 'Einfache Auswahl (Ja / Nein / Enthaltung)',
                                        'select': 'Einfache Auswahl (Eigene Optionen)',
                                        'multi_select': 'Mehrfach Auswahl',
                                        'read_and_confirm': 'Bestätigung durch Teilnehmer',
                                        'text': 'Texteingabe durch Teilnehmer',
                                    }}
                                />
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderOptionLimitInput()}
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            {renderFormButton()}
                        </Col>
                    </Row>
                    <Row>
                        <Col id="voting_options">
                            {renderForm()}
                            {renderOptionTable()}
                            {renderOptionFeedback()}
                        </Col>
                    </Row>


                    <div className="processingButtons">
                        <Button color="lightgray" onClick={toggle}>{t('button.abort')}</Button>
                        <Button color="primary" onClick={handleSubmit}>{t('button.create')}</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
};
export default AddVotingTemplate;

