import React from "react";
import {Card, CardHeader, NavLink, UncontrolledCollapse} from "reactstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const ResultDetailLog = () => {
    const {t} = useTranslation();

    const {result, voted_log, voted_count, not_voted_log, not_voted_count} = useSelector(state => state.result);

    const renderDetails = () => {

        if (result && voted_log) {
            if (Object.keys(result).length > 0 && voted_log.length > 0) {

                return (
                    <div className="resultHistory">
                        <h5>{t('headline.voted')} <br/>{t('general.participant')}: {voted_count}</h5>
                        <div>

                            <div id="accordion" className="custom-accordion mb-4 w-100">
                                {Object.keys(result).map((key) => {
                                    if (key === 'final_result_identifier') return null;
                                    if (result[key].label === 'noch nicht zur Kenntnis genommen') return null;
                                    return (
                                        <Card className="mb-1 p-0">
                                            <CardHeader className="p-0">
                                                <h5 className="m-0">
                                                    <NavLink className="custom-accordion-title d-block pt-2 pb-2" id={`group${key}`} href="#">
                                                        {result[key].label}{t('headline.number_of_votes')} {result[key].votes}
                                                        <span className="float-right">
                                                    <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
                                                </span>
                                                    </NavLink>
                                                </h5>
                                            </CardHeader>
                                            <UncontrolledCollapse toggler={`#group${key}`}>
                                                <div className="px-1 py-2">
                                                    {voted_log.map(item => {
                                                        if (item.choice === result[key].label || item.choice === true) {
                                                            return <p className={'mb-1 mr-1'} title={'Abgegebene Stimme: ' + item.label}>{item.name}</p>
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                            </UncontrolledCollapse>
                                        </Card>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            }
        }

    }

    const renderNotVotedLog = () => {

        if (not_voted_log) {
            if (not_voted_log.length > 0) {
                return (
                    <div className="resultHistory">
                        <h5>{t('headline.not_voted')}<br/>
                            {t('general.participant')}: {not_voted_count}</h5>
                        <div>
                            {not_voted_log.map(item => {
                                return <p>{item.name}</p>
                            })}
                        </div>
                    </div>
                )
            }
        }


        return (
            <div className="resultHistory">
                <h5>{t('headline.not_voted')} <br/>
                    {t('general.participant')}: {not_voted_count}</h5>
                <div>
                    {t('general.no_more_participant')}
                </div>
            </div>
        )

    }

    return (
        <>
            {renderDetails()}
            {renderNotVotedLog()}
        </>
    )

}

export default ResultDetailLog;