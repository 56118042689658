import {useSelector} from "react-redux";
import InputFeedback from "./InputFeedback";

const TimeInput = ({name,  label, value, handleInputChange}) => {

    const errors = useSelector(state => state.errors);


    const renderClass = () => {
        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors[name]) {
                return "form-control form-control is-invalid"
            }

        }

        return "form-control"
    }


    return (<div className="form-group">
        <label>{label}</label>
        <input
            type="time"
            className={renderClass()}
            aria-invalid="true"
            value={value}
            name={name}
            onChange={handleInputChange}
        />
        <InputFeedback name={name} />
    </div>)

};

export default TimeInput;