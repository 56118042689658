import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {Card, Container, Row} from "react-bootstrap";
import {getResult} from "../actions/resultAction";
import ResultDetailLog from "./ResultDetailLog";
import ResultChart from "./ResultChart";
import {showVoting} from "../actions/votingAction";
import {showAgendaItem} from "../actions/agendaItemAction";
import {useParams} from "react-router-dom";

const SingleResult = () => {

    const {voting_id} = useParams();
    const {not_voted_log} = useSelector(state => state.result);
    const {selected_voting} = useSelector(state => state.votings)
    const {selected_agenda_item} = useSelector(state => state.agenda_items);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getResult(voting_id))
        dispatch(showVoting(voting_id))
        return function cleanup() {
            dispatch({type: "CLEAR_RESULT"});
        } // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(selected_voting) {
            dispatch(showAgendaItem(selected_voting.agenda_item_id))
        }
    },[selected_voting])


    const renderFinalResult = () => {

        if (!selected_voting) return null;
        if (!selected_voting.result) return null;
        if (selected_voting.result === "") return null;

        let voting_option  = selected_voting.voting_options.filter(voting_option => voting_option.id === selected_voting.result.voting_option_id)

        return (
            <div className="resultBox">
                <p>Endergebnis</p>
                <p>Mit {selected_voting.result.votes} Stimmen wurde
                    für {voting_option[0].label} gestimmt.</p>
            </div>
        )
    }

    const renderContainer = () => {
        if (selected_voting) {
            return <Container fluid className={["single-result", selected_voting.type === 'select' || selected_voting.type === 'multi_select' || selected_voting.type === 'read_and_confirm' ? "single-result-big" : "", !not_voted_log || (not_voted_log && not_voted_log.length === 0) ? 'no-not-voted-log' : '']}>
                <Row className="headRow">
                    <h5 style={{fontSize: 18, marginBottom: 0}}>
                        Ergebnis: {selected_voting.title}<br/><br/>
                        TOP {selected_agenda_item && selected_agenda_item.order}: {selected_agenda_item && (selected_agenda_item.title)}
                    </h5>
                </Row>
                <Row>
                    <Card>
                        <Card.Body>
                            <div className="result-diagram">
                                {renderFinalResult()}
                                {<ResultChart voting={selected_voting}/>}
                            </div>
                            {<ResultDetailLog/>}
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        }
        return null;
    }


    return renderContainer()

};

export default SingleResult;