import Emailnput from "./Inputs/EmailInput";
import PasswordInput from "./Inputs/PasswordInput";
import {useState} from "react";
import {Container, Card, CardBody, CardTitle, CardHeader} from "reactstrap";
import {useDispatch} from "react-redux";
import {login} from "../actions/userAction";
import {isLogin} from "../_services/userService";
import {Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Login = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    })

    const inputChangeHandler = (e) => {
        const {name, value} = e.target;
        setCredentials(prevState => ({...prevState, [name]: value}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(credentials.email, credentials.password))
    }

    if(isLogin()) {
        return <Navigate to="/" />;
    }


    return (

        <Container fluid>
            <Card id="loginCard">
                <CardHeader><CardTitle tag="h3">Login</CardTitle></CardHeader>
                <CardBody>
                    <form>
                        <Emailnput
                            placeholder={t('placeholder.email')}
                            name="email"
                            label={t('input.email')}
                            handleInputChange={inputChangeHandler}
                        />
                        <PasswordInput
                            placeholder={t('placeholder.password')}
                            name="password"
                            label={t('input.password')}
                            handleInputChange={inputChangeHandler}
                        />
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                        >{t('button.login')}
                        </button>
                    </form>
                </CardBody>
            </Card>
        </Container>
    )

}

export default Login;