import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Committee from "./Committee";
import MeetingList from "./MeetingList";
import MeetingOverview from "./MeetingOverview";
import Login from "./Login";
import SingleResult from "./SingleResult";
import Logout from "./Logout";
import Template from "./Template";
import AgendAItemTemplate from "./AgendAItemTemplate";
import {isLogin} from "../_services/userService";


const Routing = () => (
    <Routes>
       <Route path="/login" element={ <Login />}  />
        <Route path="/" element={
                <RequireAuth redirectTo="/login">
                        <Committee />
                </RequireAuth>
        } />

        <Route path="/committee/:committeeId" element={
                <RequireAuth redirectTo="/login">
                        <MeetingList />
                </RequireAuth>
        } />

        <Route path="/meeting/:meetingId" element={
                <RequireAuth redirectTo="/login">
                        <MeetingOverview />
                </RequireAuth>
        } />

        <Route  path="/template" element={
                <RequireAuth redirectTo="/login">
                        <Template />
                </RequireAuth>
        } />

        <Route  path="/agenda_item_template/:agenda_item_template_id" element={
                <RequireAuth redirectTo="/login">
                        <AgendAItemTemplate />
                </RequireAuth>
        } />

        <Route  path="/result/:voting_id" element={
                <RequireAuth redirectTo="/login">
                        <SingleResult />
                </RequireAuth>
        } />

        <Route  path="/logout" element={
                <RequireAuth redirectTo="/login">
                        <Logout />
                </RequireAuth>
        } />
    </Routes>
);

export default Routing;

function RequireAuth({ children, redirectTo }) {
        let isAuthenticated = isLogin();
        return isAuthenticated ? children : <Navigate to={redirectTo} />;
}