const initState = {
    agenda_item_template: null,
    agenda_item_templates: [],
    agenda_item_template_waiting: false
}


const agendaItemTemplateReducer = (state = initState, action) => {
    switch (action.type) {
        case "FETCH_AGENDA_ITEM_TEMPLATES":
            return {...state, agenda_item_templates: action.payload.agenda_item_templates, agenda_item_template_waiting: false}
        case "ADD_AGENDA_ITEM_TEMPLATE":
            return {
                ...state, agenda_item_templates: state.agenda_item_templates.concat(action.payload.agenda_item_template), agenda_item_template_waiting: false
            }
        case "DELETE_AGENDA_ITEM_TEMPLATE":
            return {
                ...state,
                agenda_item_templates: state.agenda_item_templates.filter((item) => item.id !== action.payload.id),
                agenda_item_template_waiting: false
            };
        case "EDIT_AGENDA_ITEM_TEMPLATE":
            let newState = state.agenda_item_templates.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload.agenda_item_template
                }
                return item
            });

            return {
                ...state, agenda_item_templates: newState, agenda_item_template_waiting: false
            };
        case "SHOW_AGENDA_ITEM_TEMPLATE":
            return {
                ...state, agenda_item_template: action.payload.agenda_item_template, agenda_item_template_waiting: false
            }
        case "AGENDA_ITEM_TEMPLATES_LOADING":
            return {
                ...state, agenda_item_template_waiting: true
            };
        case "AGENDA_ITEM_TEMPLATES_LOADING_ABORT":
            return {
                ...state, agenda_item_template_waiting: false
            };
        case "CLEAR_AGENDA_ITEM_TEMPLATES":
            return initState;
        default:
            return state
    }
};


export default agendaItemTemplateReducer;