import {Button, Container} from "reactstrap";
import React, {useEffect, useState} from "react";
import {
    removeVotingTemplateFromAgendaItemTemplate,
    showAgendaItemTemplate
} from "../actions/agendaItemTemplateAction";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {Card, Row} from "react-bootstrap";
import ReadMoreReact from "read-more-react";
import AgendaItemTemplateVoting from "./Modals/AgendaItemTemplateVoting";
import {
    addVotingTemplate,
    deleteVotingTemplate,
    editVotingTemplate,
    getVotingTemplates
} from "../actions/votingTemplateAction";
import AddVotingTemplate from "./Forms/AddVotingTemplate";
import {useTranslation} from "react-i18next";

const AgendAItemTemplate = () => {
    const {t} = useTranslation();
    const {agenda_item_template_id} = useParams();

    const voting_template_init_state = {
        id: null,
        title: '',
        description: '',
        type: '',
        options_limit: 0,
        is_motion: false,
        voting_option_templates: [],
        motion: '',
        agenda_item_template_id:agenda_item_template_id
    }

    const {agenda_item_template, agenda_item_template_waiting} = useSelector(state => state.agenda_item_templates);
    const {voting_templates, voting_template_waiting} = useSelector(state => state.voting_templates);
    const [voting_template_modal, setVotingTemplateModal] = useState(false);
    const [voting_template_data, setVotingTemplateData] = useState(voting_template_init_state);
    const [reload_template, setReloadTemplate] = useState(false);

    const toggleVotingModal = () => setVotingTemplateModal(!voting_template_modal);
    const toggle_link_voting_template_modal = () => setLinkVotingTemplateModal(!link_voting_template_modal);


    const dispatch = useDispatch();

    const [link_voting_template_modal, setLinkVotingTemplateModal] = useState(false);

    useEffect(() => {
        dispatch(showAgendaItemTemplate(agenda_item_template_id));
        if (voting_templates.length === 0) {
            dispatch(getVotingTemplates());
        }

        return function cleanUp() {
            dispatch({type: "CLEAR_AGENDA_ITEM_TEMPLATES"});
        }// eslint-disable-next-line
    }, [])

    useEffect(() => {
            if (!voting_template_waiting && voting_template_modal) {
                dispatch(showAgendaItemTemplate(agenda_item_template_id));
                toggleVotingModal()
            }

            if (!voting_template_waiting && reload_template) {
                dispatch(showAgendaItemTemplate(agenda_item_template_id));
                setReloadTemplate(false);
            }

        },// eslint-disable-next-line
        [voting_template_waiting])

    useEffect(() => {
        if(agenda_item_template) {
            setVotingTemplateData(prevState => ({
                ...prevState,
                motion: agenda_item_template.motion
            }))
        }
    },[agenda_item_template])


    const handleClose = () => {
        dispatch({type:"CLEAR_ERRORS"})
        setVotingTemplateData(voting_template_init_state)
    }

    const handleVotingTemplateSubmit = () => {
        dispatch({type:'ALERT_CLEAR'})
        if(voting_template_data.id === null) {
            dispatch(addVotingTemplate(voting_template_data));
        } else {
            dispatch(editVotingTemplate(voting_template_data))
        }
    }

    const editVotingTemplateButtonHandler = (voting_template) => {
        setVotingTemplateData(voting_template);
        toggleVotingModal();

    }

    const deleteVotingTemplateButtonHandler = (id) => {
        dispatch(deleteVotingTemplate(id));
        setReloadTemplate(true)
    }

    const removeVotingTemplateButtonHandler = (id) => {
        dispatch(removeVotingTemplateFromAgendaItemTemplate(agenda_item_template_id, id))
    }

    const renderAgendaItemTemplateBaseInformation = () => {
        if (agenda_item_template_waiting && agenda_item_template === null) {
            return (
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            )
        }

        if (agenda_item_template === null) {
            return "Keine Informationen gefunden";
        }

        return (
            <>
                <Row className="headRow">
                    <div>
                        <Link to={`/template`}><i className="dripicons-arrow-thin-left"/></Link>
                        <h1>{agenda_item_template.title}</h1>
                    </div>
                    <div className="multipleButtons topButton">
                        <Button onClick={toggleVotingModal} color="success"><i className="mdi mdi-file-outline" style={{marginRight: 5}}/>
                            {t('button.voting_template.create')}
                        </Button>

                        {voting_template_modal && <AddVotingTemplate
                            modal={voting_template_modal}
                            toggle={toggleVotingModal}
                            form_data={voting_template_data}
                            setFormData={setVotingTemplateData}
                            handleSubmit={handleVotingTemplateSubmit}
                            handleClose={handleClose}
                        />}

                        <Button onClick={toggle_link_voting_template_modal} color="success"><i className="mdi mdi-file-outline" style={{marginRight: 5}}/>
                            {t('button.voting_template.assign')}
                        </Button>
                        {link_voting_template_modal && <AgendaItemTemplateVoting
                            toggle={toggle_link_voting_template_modal}
                            modal={link_voting_template_modal}/>}
                    </div>
                </Row>
                <Row className="meetingOverviewTile">
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                {agenda_item_template.notes}<br/>
                                <div>
                                    <span><strong>{t('general.motion')}</strong><br/>{agenda_item_template.motion}</span>
                                    <span><strong>{t('general.preliminary_remark')}</strong><br/>{agenda_item_template.preliminary_remark}</span>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </>
        )
    }

    const renderVotingTemplates = () => {

        if (agenda_item_template === null) return null;

        if(!agenda_item_template.voting_templates) return null;

        if (agenda_item_template.voting_templates.length === 0) {
            return (
                <div>
                    {t('general.no_voting_template')}
                </div>
            );
        }

        return (
            agenda_item_template.voting_templates.map(voting_template => (
                <div className="votingRow">
                    <div>
                        <h5 className="d-block mb-2">{voting_template.title} <em>({t('general.voting_type')}: {voting_template.type})</em></h5>
                        {voting_template.description &&
                        <ReadMoreReact className="d-block" key={voting_template.description} text={voting_template.description} min={1} ideal={300} max={400} readMoreText={t('general.read_more')}/>}
                    </div>
                    <button className="btn btn-info" onClick={() => editVotingTemplateButtonHandler(voting_template)}>{t('general.edit')}</button>
                    <button className="btn btn-info" onClick={() => removeVotingTemplateButtonHandler(voting_template.id)}>{t('button.voting_template.remove')}</button>
                    <button className="btn btn-danger" onClick={() => {
                        if (window.confirm(t('confirm.delete_voting_template')))
                            deleteVotingTemplateButtonHandler(voting_template.id)
                    }}>{t('button.voting_template.delete')}
                    </button>
                </div>
            ))
        )
    }


    return (
        <Container fluid>
            {renderAgendaItemTemplateBaseInformation()}
            <Row className="votingTemplateRow">
                <div className={"card"} id={"votingSection"}>
                    <Card.Body>{renderVotingTemplates()}</Card.Body>
                </div>
            </Row>
        </Container>
    )

};

export default AgendAItemTemplate;