import axios from "axios";
import {getToken} from "../_services/userService";
import {errorBuilder} from "./errorAction";

export const getVotingTemplates = () => async(dispatch) =>  {
    dispatch({type:"VOTING_TEMPLATES_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/templates/voting`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type:"FETCH_VOTING_TEMPLATES",
                payload: {
                    voting_templates: response.data.data
                }
            });

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

};


export const addVotingTemplate = (voting_data) => async(dispatch) =>  {

    dispatch({type:"VOTING_TEMPLATES_LOADING"})


    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/templates/voting`, voting_data, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "ADD_VOTING_TEMPLATE",
            payload:{
                voting_template: response.data.data,
            }
        });

    }).catch(error => {
        dispatch(errorBuilder(error));
    });
};




export const deleteVotingTemplate = (id) => (dispatch) => {
    dispatch({type:"VOTING_TEMPLATES_LOADING"})
    axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/templates/voting/${id}`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "DELETE_VOTING_TEMPLATE",
                payload: {
                    id: id
                }
            })
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });


}


export const editVotingTemplate = (voting_data) => async(dispatch) =>  {

    dispatch({type:"VOTING_TEMPLATES_LOADING"})


    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/templates/voting/${voting_data.id}`, voting_data, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "EDIT_VOTING_TEMPLATE",
            payload:{
                id: voting_data.id,
                voting_template: response.data.data,
            }
        });

    }).catch(error => {
        dispatch(errorBuilder(error));
    });
};
