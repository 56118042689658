import axios from "axios";
import {getToken, getUser} from "../_services/userService";
import {errorBuilder} from "./errorAction";


export const showOrganisation = () => async (dispatch) => {
    dispatch({type:"ORGANISATION_LOADING"})

    if(getUser() !== null) {
        let orga_id = getUser().organisation_id;
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/organisation/${orga_id}`,{
            headers: {
                'Authorization': "Bearer " + getToken()
            }
        })
            .then(response => {
                dispatch({
                    type: "SHOW_ORGANISATION",
                    payload: {
                        organisation: response.data.data
                    }
                })
            }).catch(error => {
                dispatch(errorBuilder(error));
            });
    } else {
        dispatch({type:"ORGANISATION_LOADING_ABORT"})
    }
}