import {Button, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Col, Row} from "react-bootstrap";
import React from "react";
import TextInput from "../Inputs/TextInput";
import SelectInput from "../Inputs/SelectInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import DateInput from "../Inputs/DateInput";
import TimeInput from "../Inputs/TimeInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import "../../assets/scss/tooltip.scss";
import {useTranslation} from "react-i18next";


const AddMeeting = (props) => {
    const {t} = useTranslation();

    if(!props.modal) {
        return null;
    }

    return (
        <Modal size="lg" onExit={props.onClosed} isOpen={props.modal} toggle={props.toggle} backdrop="static" className="modal-dialog-centered">
            <ModalHeader toggle={props.toggle}>{t('headline.meeting_create')}</ModalHeader>
            <ModalBody>
                <Form onSubmit={props.handleSubmit}>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.meeting_title')}*</p>
                            <FontAwesomeIcon data-tip data-for='name' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="name" place="top" effect="solid">
                                {t('description.meeting_title')}
                            </ReactTooltip>
                            <TextInput
                                name="title"
                                value={props.values.title}
                                label={false}
                                placeholder={t('placeholder.meeting_title')}
                                errors={props.errors}
                                handleInputChange={props.handleInputChange} />
                        </Col>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.meeting_type')}*</p>
                            <FontAwesomeIcon data-tip data-for='type' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="type" place="top" effect="solid">
                                {t('description.meeting_type')}
                            </ReactTooltip>
                            <div className="no-label">
                            <SelectInput
                                name="meeting_type"
                                value={props.values.meeting_type}
                                label={false}
                                placeholder={t('placeholder.meeting_type')}
                                errors={props.errors}
                                handleInputChange={props.handleInputChange}
                                options={[
                                    'Ordentlich','Außerordentlich',
                                ]}
                            />
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.date')}*</p>
                            <div className="no-label">
                            <DateInput
                                name="date"
                                value={props.values.date}
                                label={false}
                                errors={props.errors}
                                handleInputChange={props.handleInputChange} />
                            </div>
                        </Col>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.time')}*</p>
                            <div className="no-label">
                            <TimeInput
                            name="time"
                            value={props.values.time}
                            label={false}
                            errors={props.errors}
                            handleInputChange={props.handleInputChange} />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.meeting_place')}*</p>
                            <div className="no-label">
                            <TextInput
                                name="place"
                                value={props.values.place}
                                label={t('input.meeting_place')}
                                placeholder={t('placeholder.meeting_place')}
                                errors={props.errors}
                                handleInputChange={props.handleInputChange} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.administration')}</p>
                            <FontAwesomeIcon data-tip data-for='name' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="name" place="top" effect="solid">
                                {t('description.administration')}
                            </ReactTooltip>
                            <TextInput
                                name="administration"
                                value={props.values.administration}
                                label={false}
                                placeholder={t('placeholder.administration')}
                                errors={props.errors}
                                handleInputChange={props.handleInputChange} />
                        </Col>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.secretary')}</p>
                            <TextInput
                                name="secretary"
                                value={props.values.secretary}
                                label={false}
                                placeholder={t('placeholder.secretary')}
                                errors={props.errors}
                                handleInputChange={props.handleInputChange} />

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.comment')}</p>
                            <FontAwesomeIcon data-tip data-for='comment' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="comment" place="top" effect="solid">
                                {t('description.comment')}
                            </ReactTooltip>
                            <TextAreaInput
                                name="comment"
                                value={props.values.comment}
                                label={false}
                                placeholder={t('placeholder.comment')}
                                errors={props.errors}
                                handleInputChange={props.handleInputChange} />
                        </Col>
                    </Row>
                    <div className="processingButtons">
                        <Button color="lightgray" onClick={props.toggle}>{t('button.abort')}</Button>
                        <Button color="primary">{t('button.create')}</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default AddMeeting;