import {Col, Container, Row} from "react-bootstrap";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, Table, UncontrolledButtonDropdown} from "reactstrap";
import React, {useEffect, useState} from "react";
import TemplateModal from "./Modals/TemplateModal";
import {useDispatch, useSelector} from "react-redux";
import {
    addAgendaItemTemplate,
    deleteAgendaItemTemplate, editAgendaItemTemplate,
    getAgendaItemTemplates
} from "../actions/agendaItemTemplateAction";
import {Link} from "react-router-dom";
import AddVotingTemplate from "./Forms/AddVotingTemplate";
import {
    addVotingTemplate,
    deleteVotingTemplate,
    editVotingTemplate,
    getVotingTemplates
} from "../actions/votingTemplateAction";
import TextInput from "./Inputs/TextInput";

const Template = () => {

    const {agenda_item_templates, agenda_item_template_waiting} = useSelector(state => state.agenda_item_templates);
    const {voting_templates, voting_template_waiting} = useSelector(state => state.voting_templates);


    const [agenda_item_template_modal, setAgendaItemTemplateModal] = useState(false);
    const [voting_template_modal, setVotingTemplateModal] = useState(false);
    const toggleAgendaItemModal = () => setAgendaItemTemplateModal(!agenda_item_template_modal);
    const toggleVotingModal = () => setVotingTemplateModal(!voting_template_modal);
    const dispatch = useDispatch();

    const [agenda_item_template_search, setAgendaItemTemplateSearch] = useState("");
    const [voting_template_search, setVotingTemplateSearch] = useState("");


    const agenda_item_template_init_state = {
        id: null,
        title: '',
        preliminary_remark: '',
        motion: '',
        notes: '',
    }

    const voting_template_init_state = {
        id: null,
        title: '',
        description: '',
        type: '',
        options_limit: 0,
        is_motion: false,
        voting_option_templates: [],
        motion: ''
    }


    const [agenda_item_template_data, setAgendaItemTemplateData] = useState(agenda_item_template_init_state);

    const [voting_template_data, setVotingTemplateData] = useState(voting_template_init_state);

    const [filtered_agenda_item_templates, setFilteredAgendaItemtemplates] = useState(agenda_item_templates);
    const [filtered_voting_templates, setFilteredVotingTemplates] = useState(voting_templates);


    useEffect(() => {
            if (!agenda_item_template_waiting && agenda_item_template_modal) {
                toggleAgendaItemModal()
            }
        },// eslint-disable-next-line
        [agenda_item_template_waiting])

    useEffect(() => {
            if (!voting_template_waiting && voting_template_modal) {
                toggleVotingModal()
            }
        },// eslint-disable-next-line
        [voting_template_waiting])


    useEffect(() => {
        dispatch(getAgendaItemTemplates())
        dispatch(getVotingTemplates())// eslint-disable-next-line
    }, [])



    useEffect(() => {

        const timeoutId = setTimeout(() =>{
            let searched_templates = agenda_item_templates.filter(agenda_item_template => {
                return agenda_item_template.title.toLowerCase().includes(agenda_item_template_search.toLowerCase());

            })

            setFilteredAgendaItemtemplates(searched_templates);
        }, 500);
        return () => clearTimeout(timeoutId);



    },[agenda_item_template_search, agenda_item_templates])


    useEffect(() => {

        const timeoutId = setTimeout(() =>{
            let searched_templates = voting_templates.filter(voting_template => {
                if(voting_template.title.toLowerCase().includes(voting_template_search.toLowerCase())) return true;
                return voting_template.description.toLowerCase().includes(voting_template_search.toLowerCase());

            })

            setFilteredVotingTemplates(searched_templates);
        }, 500);
        return () => clearTimeout(timeoutId);



    },[voting_template_search, voting_templates])


    const handleAgendaItemTemplateSubmit = () => {
        dispatch({type:'ALERT_CLEAR'})
        if(agenda_item_template_data.id === null) {
            dispatch(addAgendaItemTemplate(agenda_item_template_data))
        } else {
            dispatch(editAgendaItemTemplate(agenda_item_template_data))
        }
    }

    const handleVotingTemplateSubmit = () => {
        dispatch({type:'ALERT_CLEAR'})
        if(voting_template_data.id === null) {
            dispatch(addVotingTemplate(voting_template_data));
        } else {
            dispatch(editVotingTemplate(voting_template_data))
        }

    }

    const handleClose = () => {
        dispatch({type: "CLEAR_ERRORS"})

        setAgendaItemTemplateData(agenda_item_template_init_state)
        setVotingTemplateData(voting_template_init_state)
    }

    const handleAgendaItemSearch = (e) => {
        const {value} = e.target
        setAgendaItemTemplateSearch(value);
    }

    const handleVotingTemplateSearch = (e) => {
        const {value} = e.target
        setVotingTemplateSearch(value);
    }

    const deleteAgendaItemTemplateButtonHandler = (id) => {
        dispatch(deleteAgendaItemTemplate(id));

    }

    const deleteVotingTemplateButtonHandler = (id) => {
        dispatch(deleteVotingTemplate(id));
    }

    const editAgendaItemTemplateButtonHandler = (agenda_item_template) => {
        setAgendaItemTemplateData(agenda_item_template)
        toggleAgendaItemModal()
    }

    const editVotingTemplateButtonHandler = (voting_template) => {
        setVotingTemplateData(voting_template);
        toggleVotingModal();

    }

    const renderVotingTemplateList = () => {
        if (voting_template_waiting && voting_templates.length === 0) {
            return (
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            )
        }

        if (voting_templates.length === 0 && !voting_template_waiting) {
            return "Zurzeit sind keine Abstimmungs Vorlagen angelegt"
        }


        return (
            <Table className="mb-0" responsive>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Typ</th>
                    <th>Beschreibung</th>
                    <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                {filtered_voting_templates.map(voting_templates => {
                    return (
                        <tr key={voting_templates.id} className="weg-list">
                            <td>
                               {voting_templates.title}
                            </td>
                            <td>
                                {voting_templates.type}
                            </td>
                            <td>
                                {voting_templates.description}
                            </td>

                            <td className="tableColumnMinSize">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color="primary">
                                        Aktion
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => editVotingTemplateButtonHandler(voting_templates)}>Bearbeiten</DropdownItem>
                                        <DropdownItem onClick={() => {
                                            if (window.confirm('Sind Sie sicher, dass sie die Vorlage löschen wollen?'))
                                                deleteVotingTemplateButtonHandler(voting_templates.id)
                                        }}>Löschen</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>

                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>

        )


    }

    const renderAgendaItemTemplateList = () => {

        if (agenda_item_template_waiting && agenda_item_templates.length === 0) {
            return (
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            )
        }


        if (agenda_item_templates.length === 0 && !agenda_item_template_waiting) {
            return "Zurzeit sind keine TOP Templates angelegt"
        }


        return (
            <Table className="mb-0" responsive>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Aktion</th>
                </tr>
                </thead>
                <tbody>
                {filtered_agenda_item_templates.map(agenda_item_template => {
                    return (
                        <tr key={agenda_item_template.id} className="weg-list">
                            <td>
                                <Link to={`/agenda_item_template/${agenda_item_template.id}`} className="flex-td">{agenda_item_template.title}
                                </Link>
                            </td>

                            <td className="tableColumnMinSize">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle caret color="primary">
                                        Aktion
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => editAgendaItemTemplateButtonHandler(agenda_item_template)}>Bearbeiten</DropdownItem>
                                        <DropdownItem onClick={() => {
                                            if (window.confirm('Sind Sie sicher, dass sie die Vorlage löschen wollen?'))
                                                deleteAgendaItemTemplateButtonHandler(agenda_item_template.id)
                                        }}>Löschen</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>

                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>

        )

    }

    return (
        <Container fluid>
            <div className="headRow">
                <Row>
                    <Col>
                        <h1>Vorlagen</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="topButton">
                            <Button className={"mr-2"} color="success" onClick={toggleAgendaItemModal}>TOP Vorlage erstellen</Button>
                            {agenda_item_template_modal && <TemplateModal handleClose={handleClose} modal={agenda_item_template_modal} toggle={toggleAgendaItemModal} values={agenda_item_template_data} setValues={setAgendaItemTemplateData} handleSubmit={handleAgendaItemTemplateSubmit}/>}
                            <Button color="success" onClick={toggleVotingModal}>Abstimmungsvorlage erstellen</Button>
                            {voting_template_modal && <AddVotingTemplate modal={voting_template_modal} toggle={toggleVotingModal} form_data={voting_template_data} setFormData={setVotingTemplateData} handleSubmit={handleVotingTemplateSubmit} handleClose={handleClose} />}
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className="overviewTable mb-4">
                <Col>
                    <h2>TOP Vorlagen</h2>
                </Col>
                <Col>
                    <TextInput name="Test" placeholder="TOP Vorlage suchen..." handleInputChange={e => {
                        handleAgendaItemSearch(e)
                    }}/>
                </Col>
                <Col>
                    {renderAgendaItemTemplateList()}
                </Col>
            </Row>

            <Row className="overviewTable mb-4">
                <Col>
                    <h2>Abstimmungsvorlagen</h2>
                </Col>
                <Col>
                    <TextInput name="Test" placeholder="Abstimmungsvorlage suchen..." handleInputChange={e => {
                        handleVotingTemplateSearch(e)
                    }}/>
                </Col>
                <Col>
                    {renderVotingTemplateList()}
                </Col>
            </Row>

        </Container>

    )
}


export default Template;