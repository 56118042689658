import {
    Modal,
    ModalBody,
    ModalHeader,
    Table,
    Button,
    Row,
    Col,
    UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import React, {useEffect, useState} from "react";
import FileUploader from "../Inputs/FileUploader";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteParticipant,
    editParticipant,
    changeParticipantPresents, editParticipantDeputy, deleteAllParticipants, forceParticipantLogout
} from "../../actions/participantAction";
import TextInput from "../Inputs/TextInput";
import SelectInput from "../Inputs/SelectInput";
import QrModal from "../Modals/QrModal";
import SingleVoteModal from "../Modals/SingleVoteModal";
import {useSortableData} from "../../_hooks";
import {Container} from "react-bootstrap";
import EmailInput from "../Inputs/EmailInput";
import {exportParticipantList, exportProtocol} from "../../actions/meetingAction";
import {useTranslation} from "react-i18next";


const ParticipantModal = (props) => {
    const {t} = useTranslation();
    const meeting = useSelector(state => state.meetings.selected_meeting)
    const {isWaiting, participants} = useSelector(state => state.participants)
    const {votings} = useSelector(state => state.votings);
    const dispatch = useDispatch();
    const [show_form, setShowForm] = useState(false);
    const [show_upload, setShowUpload] = useState(false);
    const [edit_mode, setEditMode] = useState(false);
    const [show_vote, setShowVote] = useState(false);
    const [show_qr, setShowQr] = useState(false);
    const [qr_value, setQrValue] = useState(123)
    const {errors} = useSelector(state => state.errors);
    const {message, type} = useSelector(state => state.alert)
    const [filtered_participant, setFilteredParticipant] = useState(participants);
    const {items, requestSort, sortConfig} = useSortableData(filtered_participant);
    const [query, setQuery] = useState("");


    const [edit_form_data, setEditFormData] = useState({
        id: null,
        name: '',
        email: '',
        party: '',
    })

    const [enable_edit, setEnableEdit] = useState(false);
    const [selected_participant, setSelectedParticipant] = useState(null);


    useEffect(() => {
        if (!isWaiting && show_form) {
            toggleForm();
        }
        if (!isWaiting && show_upload) {
            toggleUpload();
        }// eslint-disable-next-line
    }, [isWaiting])


    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const sortIcon = (name) => {
        if (getClassNamesFor(name) === 0) {
            return "";
        } else if (getClassNamesFor(name) === "descending") {
            return "mdi mdi-arrow-up-thick";
        } else if (getClassNamesFor(name) === "ascending") {
            return "mdi mdi-arrow-down-thick";
        }
    };

    useEffect(() => {

        const timeoutId = setTimeout(() =>{
            let searched_participants = participants.filter(participant => {
            if(participant.name.toLowerCase().includes(query.toLowerCase())) return true;
            return participant.party.toLowerCase().includes(query.toLowerCase());

        })

        setFilteredParticipant(searched_participants);
        }, 500);
        return () => clearTimeout(timeoutId);



    },[query, participants])

    const handleSearchQuery = (e) => {
        const {value} = e.target
        setQuery(value);
    }

    const handleDelete = (participant) => {
        dispatch(deleteParticipant(participant.id));
    }

    const toggleForm = () => {
        dispatch({type: "CLEAR_ERRORS"});
        setShowForm(!show_form);
        setShowUpload(false);
        setShowQr(false)
        setShowVote(false)

    };

    const toggleUpload = () => {
        setShowUpload(!show_upload);
        setShowForm(false);
        setShowVote(false)
        setShowQr(false)
    }

    const toggleVote = (participant) => {
        setShowForm(false);
        setShowUpload(false);
        setShowQr(false);
        setShowVote(!show_vote);
        setSelectedParticipant(participant);
    }
    const toggleQr = (value = null) => {
        setShowForm(false);
        setShowUpload(false);
        setShowVote(false);
        setQrValue(value);
        setShowQr(!show_qr)
    }

    const handleEditSubmit = () => {
        dispatch(editParticipant(edit_form_data))
        deactivateEditMode();
    }

    const renderEditSubmitButton = () => {

        if (enable_edit) {
            return (
                <Button color="link" onClick={() => handleEditSubmit()}>
                    <i className="dripicons-checkmark"></i>
                </Button>
            )
        } else {
            return (
                <Button color="link" disabled={true} onClick={() => handleEditSubmit()}>
                    <i className="dripicons-checkmark"></i>
                </Button>)
        }


    }

    const togglePresents = (id, value) => {
        dispatch(changeParticipantPresents(id, !value));
    }

    const changeDeputy = (e) => {
        const {name, value} = e.target;
        let form_data = {};
        if (value === "0") {
            form_data = {
                id: parseInt(name),
                deputy_type: 'User',
                deputyable_id: meeting.meeting_leader.id,
                deputy_id: 0
            }
        } else if (value !== "0") {
            form_data = {
                id: parseInt(name),
                deputy_type: '',
                deputyable_id: value,
                deputy_id: value
            }
        }

        dispatch(editParticipantDeputy(form_data));
    }

    const tableForm = () => {
        if (meeting) {

            if (show_form) {
                return (
                    <>
                        <Row>
                            <Col sm="6">
                                <TextInput
                                    handleInputChange={props.handleInputChange}
                                    label={null}
                                    name="name"
                                    placeholder="Name eingeben"
                                    value={props.values.name}/>
                            </Col>
                            <Col sm="6">
                                <EmailInput
                                    handleInputChange={props.handleInputChange}
                                    label={null}
                                    name="email"
                                    placeholder="E-Mail-Adresse eingeben"
                                    value={props.values.email}/>
                            </Col>
                            <Col sm="6">
                                <TextInput
                                    handleInputChange={props.handleInputChange}
                                    label={null}
                                    name="party"
                                    placeholder="Fraktion eingeben"
                                    value={props.values.party}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="processingButtons">
                                <Button color="lightgray" onClick={toggleForm}>Zurück</Button>
                                <Button color="primary" onClick={props.handleSubmit}>Teilnehmer {t('general.create')}</Button>
                            </Col>
                        </Row>
                    </>
                )
            } else {
                return null;
            }
        }
    }

    const importForm = () => {
        if (show_upload && !show_form) {
            return (
                <Row id="importParticipant">
                    <Col>
                        <FileUploader
                            onFileUpload={files => {
                                props.handleFileChange('participant_list', files[0])
                            }}
                            errors={errors}
                            max_files={1}
                            name="file"
                        />
                        <div className="processingButtons">
                            <Button color="lightgray" onClick={toggleUpload}>Zurück</Button>
                            <Button color="primary" onClick={props.handleFileUpload}>Importieren</Button>
                        </div>
                    </Col>
                </Row>
            )
        } else {
            return null;
        }

    }

    const voteForm = () => {
        if (show_vote) {
            let active_voting = votings.filter(item => item.active === 1 && item.paused === 0)
            return <SingleVoteModal voting={active_voting[0]} participant={selected_participant} toggle={toggleVote}/>
        } else {
            return null;
        }
    }

    const renderQr = () => {
        if (show_qr && qr_value) {
            return <QrModal toggle={toggleQr} value={qr_value}/>
        } else {
            return null;
        }

    }

    const activateEditMode = (participant) => {
        setEditMode(true);
        setEditFormData(state => ({
            ...state,
            id: participant.id,
            name: participant.name,
            email: participant.email,
            party: participant.party,

        }));
    }

    const deactivateEditMode = () => {
        setEditMode(false);
        setEnableEdit(false);
        setEditFormData(state => ({
            ...state,
            id: null,
            name: '',
            email: '',
            party: '',
        }));
    }

    const buildOptions = (id) => {
        var option_object = {};
        participants.map(particpant => {
            if (particpant.id !== id) {
                option_object[particpant.id] = particpant.name;
            }
            return null;
        })
        option_object[0] = meeting.meeting_leader.name + ' (Versammlungsleiter)';
        return option_object;
    }

    const inputChangeHandler = (e) => {
        const {name, value} = e.target;

        setEditFormData(prevState => ({...prevState, [name]: value}));
        setEnableEdit(true)
    }

    const modalTitle = () => {
        if (show_upload) {
            return 'Teilnehmerliste: Teilnehmer importieren'
        }
        if (show_form) {
            return 'Teilnehmerliste: Teilnehmer hinzufügen'
        } else {
            return 'Teilnehmerliste'
        }
    }

    const participant_logout = (participant_id) => {
        dispatch(forceParticipantLogout(participant_id));

    }

    const renderEditActionButton = (participant) => {
        if (meeting.closed) {
            return "";
        }
        if (edit_mode && edit_form_data.id === participant.id) {
            return (
                <>
                    {renderEditSubmitButton()}
                    <Button color="link" onClick={() => deactivateEditMode()}>
                        <i className="dripicons-cross"></i>
                    </Button>
                </>)

        } else {
            return (
                <UncontrolledButtonDropdown>
                    <DropdownToggle caret color="primary">
                        Aktion
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => activateEditMode(participant)}>Bearbeiten</DropdownItem>
                        <DropdownItem onClick={() => {
                            if (window.confirm('Sind Sie sicher, dass sie das Element löschen wollen?')) handleDelete(participant)
                        }}>Löschen</DropdownItem>
                        {participant.app_login ? <DropdownItem onClick={() => participant_logout(participant.id)} >Ausloggen</DropdownItem> : ""}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>)
        }
    }

    const handleDeleteAll = () => {
        dispatch(deleteAllParticipants(meeting.id));
    }

    const renderRemoveAllButton = () => {
        if (!meeting) return null;
            if (meeting.closed) {
                return "";
            }
            if (participants.length) {
                return <Button color="danger" onClick={() => {
                    if (window.confirm('Sind Sie sicher, dass sie alle Teilnehmer löschen wollen?')) handleDeleteAll()
                }}>Teilnehmerliste leeren</Button>
            }
        }

    const renderAddParticipantButton = () => {

        if(!meeting) return null;
        if (meeting && Object.keys(meeting).length === 0 && meeting.constructor === Object) return null;

        if (meeting.closed) return null;

        return (
            <Button color="primary" onClick={toggleForm}>Teilnehmer hinzufügen</Button>
        )
    }

    const downloadParticipantListButtonHandler = () => {

        dispatch(exportParticipantList(meeting.id));
    }


    const renderImportParticipantButton = () => {

        if(!meeting) return null;
        if (meeting && Object.keys(meeting).length === 0 && meeting.constructor === Object) return null;
        if (meeting.closed) return null;

        return (
            <Button onClick={toggleUpload}>
                Teilnehmerliste importieren
            </Button>
        )
    }

    const participantTable = () => {
        if (!show_upload && !show_form) {
            return (
                <>
                    <Row>
                        <Col>
                            <TextInput name="Test" placeholder="Suche..." handleInputChange={e => {
                                handleSearchQuery(e)
                            }}/>
                            {renderImportParticipantButton()}
                            <Button onClick={downloadParticipantListButtonHandler}>Teilnahmecodes
                                exportieren</Button>
                            {renderAddParticipantButton()}
                            {renderRemoveAllButton()}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="my-4 vollmacht"><strong>Hinweis Vollmachten an externe Dritte:</strong><br/>Namen der externen Personen können durch eine Ergänzung im Namensfeld des Eigentümers gekennzeichnet werden.<br/>z.B. "Max Mustermann vertr. d. Erna Müller"</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table>
                                <thead className="thNoBorder">
                                <tr>
                                    <th className={getClassNamesFor('name')} onClick={() => requestSort('name')}>Name<i className={sortIcon('name')}/>
                                    </th>
                                    <th className={getClassNamesFor('email')} onClick={() => requestSort('email')}>E-Mail-Adresse<i className={sortIcon('email')}/>
                                    </th>
                                    <th className={getClassNamesFor('party')} onClick={() => requestSort('party')}>Fraktion<i className={sortIcon('party')}/>
                                    </th>
                                    <th className="tableColumnMinSize">Codes</th>
                                    {meeting.closed ? "" : <th className="tableColumnMinSize">Anwesenheit</th>}
                                    <th>Vertreter</th>
                                    {meeting.closed ? "" : <th className="tableColumnMinSize">Aktion</th>}

                                </tr>
                                </thead>
                                <tbody>
                                {items.map(participant => (
                                    <tr key={participant.id}>
                                        <td>
                                            {edit_mode && edit_form_data.id === participant.id ?
                                                (<TextInput
                                                    value={edit_form_data.name}
                                                    handleInputChange={inputChangeHandler}
                                                    name="name"
                                                />)
                                                :
                                                (participant.name)
                                            }
                                            {/* If online, add class "online" */}
                                            {participant.app_login ? (!edit_mode || edit_form_data.id !== participant.id) ? (
                                                    <span className="onlineStatus online"/>) :
                                                (<span className="onlineStatus"/>) : (<span className="onlineStatus"/>)}

                                        </td>
                                        <td>
                                            {edit_mode && edit_form_data.id === participant.id ?
                                                (<EmailInput
                                                    value={edit_form_data.email}
                                                    handleInputChange={inputChangeHandler}
                                                    name="email"
                                                />)
                                                :
                                                (participant.email)
                                            }
                                        </td>
                                        <td>
                                            {edit_mode && edit_form_data.id === participant.id ?
                                                (<TextInput
                                                    value={edit_form_data.party}
                                                    handleInputChange={inputChangeHandler}
                                                    name="party"
                                                />)
                                                :
                                                (participant.party)
                                            }
                                        </td>
                                        <td className="tdQrCode">{participant.token ?
                                            <i onClick={() => toggleQr(participant.token)} className="mdi mdi-qrcode-scan"></i> : null}<span>{participant.token}</span>
                                        </td>

                                        {meeting.closed ? "" : participant.deputyable_id ? <td>&nbsp;</td> : (
                                            <td className="tdPresent">
                                                <input
                                                    type="checkbox"
                                                    aria-invalid="true"
                                                    name="present"
                                                    onChange={() => togglePresents(participant.id, participant.present)}
                                                    checked={participant.present ? true : false}

                                                />
                                            </td>)}

                                        <td>

                                            {participant.present ? "Anwesende Teilnehmer können nicht vertreten werden!" :
                                                (<SelectInput options={buildOptions(participant.id)}
                                                              placeholder="Kein Vertreter"
                                                              handleInputChange={changeDeputy}
                                                              name={participant.id}
                                                              disabled={meeting.closed}
                                                              enable_zero={true}
                                                              value={participant.deputyable_type === "App\\Models\\User" ? 0 : participant.deputyable_id === null ? "" : participant.deputyable_id}
                                                />)
                                            }
                                        </td>
                                        {meeting.closed ? "" :
                                            (<td className="editButtons">
                                                {renderEditActionButton(participant)}
                                            </td>)
                                        }

                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>
            )
        } else {
            return null;
        }
    }

    return (
        <Modal size="md" onExit={props.handleClose} isOpen={props.modal} backdrop="static" toggle={props.toggle} unmountOnClose={true} scrollable={true} className={show_upload || show_form ? "modal-dialog-centered" : "modal-dialog-centered xxl"} id="memberlist">
            <ModalHeader toggle={props.toggle}>{modalTitle()}</ModalHeader>
            {message &&
            <Container fluid>
                <Row>
                    <div className={`${type} alert-dismissible fade show`} role="alert">
                        <p>{message}</p>
                        <button type="button" className="btn-close" aria-label="Close"
                                data-bs-dismiss="alert"
                                onClick={() => dispatch({type: "ALERT_CLEAR"})}><i
                            className="mdi mdi-close"/></button>
                    </div>
                </Row>
            </Container>
            }
            <ModalBody>
                {participantTable()}
                {tableForm()}
                {importForm()}
                {voteForm()}
                {renderQr()}
            </ModalBody>
        </Modal>
    )

}


export default ParticipantModal;