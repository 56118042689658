import {ModalHeader, Modal, ModalBody, Button, Form, Row, Table} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {addVotingTemplateToAgendaItemTemplate} from "../../actions/agendaItemTemplateAction";
import TextInput from "../Inputs/TextInput";
import {useTranslation} from "react-i18next";

const AgendaItemTemplateVoting = ({modal, toggle}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const {voting_templates, voting_templates_waiting} = useSelector(state => state.voting_templates)
    const {agenda_item_template, agenda_item_template_waiting} = useSelector(state => state.agenda_item_templates)

    const [submitted, setSubmitted] = useState(false);
    const [selected_items, setSelectedItems] = useState({});
    const [query, setQuery] = useState("");
    const [filter_voting_templates, setFilterVotingTemplates] = useState(voting_templates);
    const [has_motion, setHasMotion]  = useState(null);


    useEffect(() => {
        for (let i = 0; i < agenda_item_template.voting_templates.length; i++) {
            if(voting_templates[i].is_motion) {
                setHasMotion(voting_templates[i].id);
            }

            setSelectedItems(prevState => {
                return {...prevState, [voting_templates[i].id]: true}
            });
        }
// eslint-disable-next-line
    },[])

    useEffect(() => {

        const timeoutId = setTimeout(() =>{
            let searched_templates = voting_templates.filter(voting_template => {
                if(voting_template.title.toLowerCase().includes(query.toLowerCase())) return true;
                return voting_template.description.toLowerCase().includes(query.toLowerCase());

            })

            setFilterVotingTemplates(searched_templates);
        }, 500);
        return () => clearTimeout(timeoutId);
// eslint-disable-next-line
    },[query, voting_templates])


    const handleSearchQuery = (e) => {
        const {value} = e.target
        setQuery(value);
    }


    useEffect(() => {
        if (!agenda_item_template_waiting && submitted) {
            toggle();
        }// eslint-disable-next-line
    }, [agenda_item_template_waiting,submitted])


    const handleInputChange = (e) => {
        const {checked, value} = e.target;
        if(checked) {
            let motion_check = voting_templates.filter(voting_template => {
                return (voting_template.id === parseInt(value) && voting_template.is_motion);
            })
            if(motion_check.length > 0) {
                setHasMotion(parseInt(value));
            }


            setSelectedItems(prevState => {
                return {...prevState, [value]: checked}
            });


        } else {
            if (value in selected_items) {
                if(parseInt(value) === has_motion) {
                    setHasMotion(null);
                }

                setSelectedItems((prevState) => {
                    const {[value]: remove, ...rest} = prevState;
                    return rest;
                })
            }
        }
    }


    const handleSubmit = () => {
        dispatch(addVotingTemplateToAgendaItemTemplate(agenda_item_template.id, {voting_templates:Object.keys(selected_items)}))
        setSubmitted(true);
    }

    const renderAvailableVotingTemplates = () => {
        if (voting_templates_waiting && voting_templates.length === 0) {
            return (
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            )
        }

        if (voting_templates.length === 0 && !voting_templates_waiting) {
            return t('general.no_voting_template')
        }


        const renderSelect = (template) => {
            if (!template.is_motion || has_motion === null) {
                return (
                    (<input
                        type="checkbox"
                        value={template.id}
                        onChange={handleInputChange}
                        checked={template.id in selected_items}
                    />)
                )
            }

            if (has_motion === template.id) {
                return (
                    (<input
                        type="checkbox"
                        value={template.id}
                        onChange={handleInputChange}
                        checked={template.id in selected_items}
                    />)
                )
            } else {
                return (t('error.motion_exists'));
            }


        }



        return (
            <Table className="mb-0" responsive>
                <thead>
                <tr>
                    <th>{t('general.selection')}</th>
                    <th>{t('general.name')}</th>
                    <th>{t('input.description')}</th>
                    <th>{t('general.motion')}</th>
                    <th>{t('general.type')}</th>
                </tr>
                </thead>
                <tbody>
                {filter_voting_templates.map(filter_voting_template => {
                    return (
                        <tr key={filter_voting_template.id} className="weg-list">
                            <td>
                                {renderSelect(filter_voting_template)}
                            </td>

                            <td>
                                {filter_voting_template.title}
                            </td>
                            <td>
                                {filter_voting_template.description}
                            </td>
                            <td>
                                {filter_voting_template.is_motion ? "Ja" : "Nein"}
                            </td>
                            <td>
                                {filter_voting_template.type}
                            </td>

                        </tr>
                    )
                })}
                </tbody>
            </Table>
        )

    }

    return(
        <Modal size="xl" isOpen={modal} toggle={toggle} backdrop="static" className="modal-dialog-centered">
            <ModalHeader toggle={toggle}>{t('headline.voting_template_assign')}</ModalHeader>
            <ModalBody>
                <TextInput name="Test" placeholder="Suche..." handleInputChange={e => {
                    handleSearchQuery(e)
                }}/>
                <Form>
                    <Row>
                        <Col>
                            {renderAvailableVotingTemplates()}
                        </Col>
                    </Row>

                    <div className="processingButtons">
                        <Button color="lightgray" onClick={toggle}>{t('button.abort')}</Button>
                        <Button onClick={handleSubmit} color="primary">{t('button.assign')}</Button>
                    </div>
                </Form>

            </ModalBody>
        </Modal>
    )


};

export default AgendaItemTemplateVoting;