import React, { useState } from "react";
import {useSelector} from "react-redux";
import { Tooltip } from 'reactstrap';
import InputFeedback from "./InputFeedback";

const SelectInput = ({name, label, tooltipText, placeholder, value = '', options, handleInputChange, enable_zero = false, disabled = false}) => {

    const errors = useSelector(state => state.errors);


    const renderClass = () => {
        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors[name]) {
                return "form-control form-control is-invalid"
            }

        }

        return "form-control"
    }

    const renderOptions = () => {
        if (Array.isArray(options)) {
            return options.map(option => {
                    return <option  value={option.trim()}>{option.trim()}</option>
            })
        } else if (typeof options === 'object' && options !== null) {
            return Object.keys(options).map(key => {
                    return <option value={key.trim()}>{options[key].trim()}</option>
            })
        }
    }

    const LabelTooltip = () => {
        const [tooltipOpen, setTooltipOpen] = useState(false);

        const toggle = () => setTooltipOpen(!tooltipOpen);

        if (tooltipText) {
            return (
                <><i className="mdi mdi-help-circle tooltip-icon" id={name}></i>
                    <Tooltip placement="top" isOpen={tooltipOpen} target={name} toggle={toggle}>
                        {tooltipText}
                    </Tooltip>
                </>
            );
        } else {
            return '';
        }
    }


    return (<div className="form-group">
        <label>{label}{LabelTooltip()}</label>
        <select
            name={name}
            className={renderClass()}
            onChange={handleInputChange}
            value={value}
            disabled={disabled}
        >
            {enable_zero ? (<option value="">{placeholder}</option>):(<option disabled={true} value="">{placeholder}</option>)}

            {renderOptions()} />
        </select>
        <InputFeedback name={name} />
    </div>)


};

export default SelectInput;