import {useSelector} from "react-redux";
import InputFeedback from "./InputFeedback";

const TextAreaInput = ({name, label, placeholder, value, handleInputChange, rows, cols}) => {

    const errors = useSelector(state => state.errors);

    const renderClass = () => {
        if (Object.entries(errors.errors).length !== 0) {
            if (errors.errors[name]) {
                return "form-control form-control is-invalid"
            }

        }

        return "form-control"
    }


    return (
        <div className="form-group">
            {label ? <label>{label}</label> : ""}
            <textarea
                placeholder={placeholder}
                className={renderClass()}
                aria-invalid="true"
                value={value}
                name={name}
                onChange={handleInputChange}
                rows={rows}
                cols={cols}
            />
            <InputFeedback name={name} />
        </div>)

};

export default TextAreaInput;