import axios from "axios";
import {errorBuilder} from "./errorAction";
import {getToken, isLogin, logout} from "../_services/userService";
import {showOrganisation} from "./organisationAction";

export const login = (username, password) => async (dispatch) => {
    dispatch({type:"USER_LOADING"});
    await axios.post(`${process.env.REACT_APP_AUTH_ENDPOINT}/token`, {
            email: username,
            password: password,
            device_name: "admin_ui"

        }, {
            headers: {
                'Accept': 'application/json'
            }
        }
    )
        .then(response => {
            localStorage.setItem('token', JSON.stringify(response.data.token))
            dispatch({type:"ALERT_CLEAR"});
            dispatch({
                type: "LOGIN",
            });
        })
        .catch(error => {
            dispatch(errorBuilder(error));
        })
}

export const logoutAction = () => async (dispatch) => {
        await axios.get(`${process.env.REACT_APP_AUTH_ENDPOINT}/logout`, {
                headers: {
                    'Authorization': "Bearer " + getToken()
                }
            }
        )
            .then(response => {
                logout();
                dispatch({type: "LOGOUT"});
            })
            .catch(error => {
                dispatch(errorBuilder(error));
            });


}

export const getUserDeputys = (meeting_id) => async (dispatch) => {
    dispatch({type:"USER_LOADING"});
     axios.get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/participants/user`, {
            headers: {
                'Authorization': "Bearer " + getToken()
            }
        }
    )
        .then(response => {
            dispatch({
                type: "USER_DEPUTYS",
                payload: {
                    deputys: response.data.data,
                    //deputed_votes: response.data.votes
                }
            });

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });

}




export const getUser = () => async (dispatch) => {
    dispatch({type:"USER_LOADING"});
    if (!isLogin()) {
        dispatch({type:"USER_LOADING_ABORT"});
    }
     await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/user`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            localStorage.setItem('user', JSON.stringify(response.data.data))
            dispatch({
                type: "FETCH_USER",
                payload: {
                    user: response.data.data,
                }
            });
            dispatch(showOrganisation());

    })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
}
