import React, {useEffect, useState} from "react";
import {
    UncontrolledCollapse,
    Card,
    CardBody,
    CardHeader,
    NavLink,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, UncontrolledButtonDropdown, Button
} from "reactstrap";
import {deleteAgendaItem, deleteFile, getFile} from "../actions/agendaItemAction";
import {useDispatch, useSelector} from "react-redux";
import Voting from "./Voting";
import ReadMoreReact from 'read-more-react';
import "../assets/scss/slideOut.scss";
import {useTranslation} from "react-i18next";

const AgendaItem = (props) => {
    const {t} = useTranslation();
    const {votings} = useSelector(state => state.votings);
    const dispatch = useDispatch();
    const {selected_meeting} = useSelector(state => state.meetings);

    const [closedClass, setClosedClass] = useState("");

    useEffect(() => {
        if(props.item.votings.length === 0) {
            setClosedClass("")
        }
        else if (props.item.votings.filter(voting => (voting.stopped === 0)).length === 0){
            setClosedClass('closed')
        } else {

        }
    },[props.item])
    const addVotingButton = () => {

        for (let i = 0; i<votings.length; i++) {
            if(votings[i].agenda_item_id === props.item.id) {
                if(votings[i].is_motion === 1) {
                    props.setFormData(state => ({...state, motion_exists: true}))
                }
            }
        }


        props.setFormData(state => ({...state, agenda_item_id: props.item.id, motion: props.item.motion}))
        props.toggleVotingModal();
    }

    const renderVotings = () => {

        let isVotingActive = false;
        if(votings.find(element => element.active === 1)){
            isVotingActive = true;
        }

        if(votings.find(element => element.agenda_item_id === props.item.id)) {
            return (
                <>
                    <h4>Abstimmungen</h4>
                    <div id="votingSection">
                        {votings.map(voting => {
                            if (voting.agenda_item_id === props.item.id) {
                                return <Voting deputy_vote_enabled={props.deputy_vote_enabled} key={voting.id} voting={voting} isVotingActive={isVotingActive} toggelShowResult={props.toggleShowResult} toggleDeputyVote={props.toggleDeputy}/>
                            } else {
                                return null;
                            }

                        })}
                    </div>
                </>
            )
        }
    }

    const deleteButtonHandler = (e) => {
        dispatch({type:"ALERT_CLEAR"});
        dispatch(deleteAgendaItem(e));
    }

    const removeFile = (id) => {
        dispatch(deleteFile(id));
    }

    const downloadFile = (id,name) => {
        dispatch(getFile(id, name))
    }

    const renderFileIcon = (type) => {
        switch (type) {
            case "application/pdf": return <i className="mdi mdi-file-pdf-outline"/>;
            case "application/msexcel": return <i className="mdi mdi-file-excel-outline"/>;
            case "application/msword": return <i className="mdi mdi-file-word-outline"/>;
            case "application/vnd.ms-excel": return <i className="mdi mdi-file-excel-outline"/>;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": return <i className="mdi mdi-file-excel-outline"/>;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": return <i className="mdi mdi-file-word-outline"/>;
            case "application/mspowerpoint": return <i className="mdi mdi-file-powerpoint-outline"/>;
            case "image/png": return <i className="mdi mdi-file-image-outline"/>;
            case "image/jpeg": return <i className="mdi mdi-file-image-outline"/>;
            case "text/plain": return <i className="mdi mdi-file-code-outline"/>;
            default: return <i className="mdi mdi-file-code-outline"/>
        }
    }

    const renderFileList = () => {
        if(!props.item.files.length === 0) return null;

        return (
            <ul>
                {props.item.files.map(file => {
                    return (
                        <li>{renderFileIcon(file.type)}<span>{file.name}</span><i onClick={() => downloadFile(file.id, file.name)} className="mdi mdi-download"/><i onClick={() => removeFile(file.id)} className="mdi mdi-delete"/></li>
                    )
                })}
            </ul>
        )
    }


    return (
        <div id={`accodion-${props.item.id}`} className={`mb-4 ${closedClass}`}>
            <Card>
                <CardHeader>
                    <h5 className="m-0">
                        <NavLink className="custom-accordion-title d-block pt-2 pb-2" id={`groups${props.item.id}`}
                                 href="#">
                            <div className="agendaItemTitleLink">
                                <span>TOP {props.item.order}:<br/>{props.item.title}</span><br/>
                                <span className="abstimmungenCount">{votings.filter(item => item.agenda_item_id === props.item.id).length} Abstimmungen</span>
                            </div>
                            <span className="float-right">
                                    <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
                                </span>
                        </NavLink>

                            {selected_meeting.closed ? "": (
                                <div>
                                <Button onClick={addVotingButton} color="info">{t('button.voting.add')}</Button>
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle color="link">
                                            <i className="dripicons-dots-3"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                onClick={() => props.editButtonHandler(props.item.id)}>{t('general.edit')}</DropdownItem>
                                            <DropdownItem onClick={() => {
                                                if (window.confirm(t('confirm.delete_top'))) deleteButtonHandler(props.item.id)
                                            }}>Löschen</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            )}

                    </h5>

                </CardHeader>
                <UncontrolledCollapse toggler={`#groups${props.item.id}`}>
                    <CardBody>
                        <div className="topDetails">
                            <div>
                                <p style={{whiteSpace: "pre-wrap"}}><strong>{t('input.preliminary_remark')}:</strong><br/>
                                    {props.item.preliminary_remark !== null ? <ReadMoreReact text={props.item.preliminary_remark} min={1} ideal={300} max={400} readMoreText={t('general.read_more')} /> : t('general.no_preliminary_remark')}
                                </p>
                                <p style={{whiteSpace: "pre-wrap"}}><strong>{t('input.motion')}:</strong><br/>
                                    {props.item.motion !== null ? <ReadMoreReact text={props.item.motion} min={1} ideal={300} max={400} readMoreText={t('general.read_more')} /> : t('general.no_motion')}
                                </p>
                                <p style={{whiteSpace: "pre-wrap"}}><strong>{t('input.notes')}:</strong><br/>
                                    {props.item.notes !== null ? <ReadMoreReact text={props.item.notes} min={1} ideal={300} max={400} readMoreText={t('general.read_more')} /> : t('general.no_note')}

                                </p>
                            </div>
                            <div>
                                <p>{t('general.appendix')}</p>
                                {renderFileList()}
                            </div>
                        </div>
                        {renderVotings()}
                    </CardBody>
                </UncontrolledCollapse>
            </Card>
        </div>
    );
}


export default AgendaItem;