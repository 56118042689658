import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {Row, Col} from "react-bootstrap";
import {Button, Form, FormGroup} from 'reactstrap';
import FileUploader from "../Inputs/FileUploader";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import "../../assets/scss/tooltip.scss";
import {useTranslation} from "react-i18next";



const AddAgendaItem = ({handleClose,handleSubmit, handleInputChange,handleFileChange,modal,toggle,values}) => {

    const {t} = useTranslation();
    const meeting = useSelector(state => state.meetings.selected_meeting)

    const {errors} = useSelector(state => state.errors)

    if (!modal || meeting === null) {
        return null;

    }

    return (
        <Modal size="xl" onExit={handleClose} isOpen={modal} toggle={toggle} backdrop="static" className="modal-dialog-centered addTopModal">
            <ModalHeader toggle={toggle}>{t('button.agenda_item'.create)}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col sm="12">
                            <p className="d-inline-block mb-1" >{t('input.name')}*</p>
                            <FontAwesomeIcon data-tip data-for='top-name' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="top-name" place="top" effect="solid">
                                {t('description.agenda_item')}
                            </ReactTooltip>
                            <TextAreaInput name="title" value={values.title} label={false} placeholder={t('placeholder.name')} handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row className="threeColumnRow">
                        <Col lg="4">
                            <p className="d-inline-block mb-1" >{t('input.order')}*</p>
                            <FontAwesomeIcon data-tip data-for='top-nr' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="top-nr" place="top" effect="solid">
                                {t('description.order')}
                            </ReactTooltip>
                            <TextInput name="order" value={values.order} label={false} placeholder="5" handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.notes')}</p>
                            <FontAwesomeIcon data-tip data-for='note' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="note" place="top" effect="solid">
                                {t('description.notes')}
                            </ReactTooltip>
                            <TextAreaInput name="notes" value={values.notes} label={false} placeholder={t('placeholder.notes')} handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.preliminary_remark')}</p>
                            <FontAwesomeIcon data-tip data-for='remark' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="remark" place="top" effect="solid">
                                {t('description.preliminary_remark')}
                            </ReactTooltip>
                            <TextAreaInput name="preliminary_remark" value={values.preliminary_remark} label={false} placeholder={t('placeholder.preliminary_remark')} handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="d-inline-block mb-1" >{t('input.motion')}</p>
                            <FontAwesomeIcon data-tip data-for='resolution' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                            <ReactTooltip className="custtooltip" id="resolution" place="top" effect="solid">
                                    {t('description.motion')}
                            </ReactTooltip>
                            <TextAreaInput name="motion" value={values.motion} label={false} placeholder={t('placeholder.motion')}  handleInputChange={handleInputChange}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <p className="d-inline-block mb-1" >{t('input.file')}</p>
                                <FontAwesomeIcon data-tip data-for='upload' className='ml-1 custtooltip-trigger' icon={faInfoCircle} />
                                <ReactTooltip className="custtooltip" id="upload" place="top" effect="solid">
                                    {t('description.file')}
                                </ReactTooltip>
                                <FileUploader
                                    onFileUpload={files => {
                                        handleFileChange('files', files)
                                    }}
                                    name="files"
                                    max_files={5}
                                    errors={errors}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="processingButtons">
                        <Button color="lightgray" onClick={toggle}>{t('button.abort')}</Button>
                        <Button color="primary">{t('button.create')}</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>

    )
}

export default AddAgendaItem;