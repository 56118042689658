import {Button, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import React from "react";
import TextInput from "../Inputs/TextInput";
import {useTranslation} from "react-i18next";

const AddCommittee = (props) => {
    const {t} = useTranslation();
    return (
        <Modal onExit={props.onColsed} isOpen={props.modal} backdrop="static" toggle={props.toggle} className="modal-dialog-centered">
            <ModalHeader toggle={props.toggle}>{t('headline.committee_create')}</ModalHeader>
            <ModalBody>
                <Form onSubmit={props.handleSubmit}>
                    <TextInput name="name" errors={props.errors} handleInputChange={props.handleInputChange} value={props.inputValue} label={t('input.committee')} placeholder={t('placeholder.name')} />
                    <div className="processingButtons">
                        <Button color="lightgray" onClick={props.toggle}>{t('button.abort')}</Button>
                        <Button color="primary">{t('button.create')}</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default AddCommittee;