import React, { Component } from 'react';
import {Card} from 'reactstrap';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

type FileUploaderProps = {
    onFileUpload?: PropTypes.func,
    showPreview?: boolean,
};

type FileUploaderState = {
    selectedFiles: Array<PropTypes.object>,
};

class FileUploader extends Component<FileUploaderProps, FileUploaderState> {
    static defaultProps = {
        showPreview: true,
    };

    constructor(props: FileUploaderProps) {
        super(props);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);

        this.state = {
            selectedFiles: [],
        };
    }


    /**
     * Handled the accepted files and shows the preview
     */
    handleAcceptedFiles = (files: Array<PropTypes.object>) => {

        var allFiles = files;

        if (this.props.showPreview) {
            files.map(file =>
                Object.assign(file, {
                    preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
                    formattedSize: this.formatBytes(file.size),
                })
            );

            allFiles = this.state.selectedFiles;
            allFiles.push(...files);
            this.setState({ selectedFiles: allFiles });
        }

        if (this.props.onFileUpload) this.props.onFileUpload(allFiles);
    };

    removeFile = (file) => {
        let allFiles = this.state.selectedFiles;
        allFiles.splice(this.state.selectedFiles.indexOf(file), 1)
        this.setState({ selectedFiles: allFiles });
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    renderDropzone = () => {
        if (this.state.selectedFiles.length < this.props.max_files) {
            return (
                <Dropzone {...this.props} onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div className="dz-message needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <i className="h1 text-muted dripicons-cloud-upload"></i>
                                <h5>Dateien hier hinziehen oder klicken</h5>
                            </div>
                        </div>
                    )}
                </Dropzone>

                )
        } else {

            return (
                <Dropzone {...this.props} onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)} disabled={true}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div className="dz-message needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <span>Maximale Anzahl an Dateien erreicht</span>
                            </div>
                        </div>
                    )}
                </Dropzone>
            )

        }
    }

    renderFeedback = () => {
        if (Object.entries(this.props.errors).length !== 0) {
            if (this.props.max_files > 1) {
                for (let i  = 0; i< this.props.max_files; i++) {
                    if (this.props.errors[this.props.name+'.'+i]) {
                        const keyElemet = this.props.errors[this.props.name+'.'+i];
                        return (
                            <div>
                                {Object.entries(keyElemet).map(([key, value]) => {
                                    return value.map(val => {
                                        return <p className="errorMessage">{val.message}</p>
                                    })
                                })}
                            </div>
                        )
                    }
                }
            } else {
                if (this.props.errors[this.props.name]) {
                    const keyElemet = this.props.errors[this.props.name];
                    return (
                        <div>
                            {Object.entries(keyElemet).map(([key, value]) => {
                                return value.map(val => {
                                    return <p className="errorMessage">{val.code}: {val.message}</p>
                                })
                            })}
                        </div>
                    )
                }
            }


        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderDropzone()}
                {this.props.showPreview && (
                    <div className="dropzone-previews" id="file-previews">
                        {this.state.selectedFiles.map((f, i) => {
                            return (
                                <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + '-file'}>
                                    <div className="p-2 file-tile">
                                        {f.preview && (
                                            <img
                                                data-dz-thumbnail=""
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                            />
                                        )}
                                        {!f.preview && (
                                            <div className="avatar-sm">
                                                        <span className="avatar-title rounded">
                                                            {f.type.split('/')[0]}
                                                        </span>
                                            </div>
                                        )}
                                        <div>
                                            {/*<Link to="#" className="font-weight-bold">
                                                {f.name}
                                            </Link>*/}
                                            <span className="font-weight-bold mr-1">
                                                {f.name}
                                            </span>
                                            <span className="mb-0">
                                                    <span className="text-muted">{f.formattedSize}</span>
                                                </span>
                                        </div>
                                        <i onClick={() => this.removeFile(f)} className="mdi mdi-delete"/>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                )}
                {this.renderFeedback()}
            </React.Fragment>
        );
    }
}

export default FileUploader;
