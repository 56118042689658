import {useEffect} from "react";
import {logoutAction} from "../actions/userAction";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const Logout = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate()

    useEffect(() => {
        dispatch({type: "CLEAR_ERRORS"});
        dispatch({type: "CLEAR_AGENDA_ITEMS"});
        dispatch({type: "CLEAR_MEETINGS"});
        dispatch({type: "CLEAR_VOTINGS"});
        dispatch({type: "CLEAR_PARTICIPANTS"});
        dispatch({type: "CLEAR_WEGS"});
        dispatch({type: "ALERT_CLEAR"});
        dispatch(logoutAction());
        navigate('/login')

    },[dispatch])

    return (
        <></>
    )

};


export default Logout;