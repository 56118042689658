import axios from "axios";
import {errorBuilder} from "./errorAction";
import {getToken} from "../_services/userService";

export const getParticipants = (meeting_id) => async (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"})
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/participants`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "FETCH_PARTICIPANTS",
                payload: {
                    participants: response.data.data,
                    /*meeting_leader: response.data.meeting_leader,
                    quorum: response.data.quorum*/
                }
            });
        }).catch(function (error) {
            dispatch(errorBuilder(error));
        });
};

export const deleteParticipant = (id) => (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"})
    axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/participant/${id}`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "DELETE_PARTICIPANT",
                payload: {
                    id: id,
                }
            });
            dispatch({type: "ALERT_SUCCESS", payload: {
                    message: "Teilnehmer gelöscht."
                }});

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
};

export const deleteAllParticipants = (meeting_id) => (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"})
    axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/participants`,{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "DELETE_ALL_PARTICIPANT",
            });

        })
        .catch(error => {
            dispatch(errorBuilder(error));
        });
}

export const addParticipant = (participant_data, meeting_id) => async (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"})
    await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/participant`, {
        name: participant_data.name,
        email: participant_data.email,
        party: participant_data.party,
        meeting_id: meeting_id,

    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "ADD_PARTICIPANT",
            payload: {
                participant: response.data.data,
            }

        });
        dispatch({type: "ALERT_SUCCESS", payload: {
                message: "Teilnehmer erfolgreich angelegt."
            }});
    }).catch(error => {
        dispatch(errorBuilder(error));
    });
}

export const importParticipant = (meeting_id, participant_list) => async (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"})
    const formData = new FormData();
    formData.append('file', participant_list, participant_list.name);

   await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/participants/import`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "IMPORT_PARTICIPANTS",
                payload: {
                    participants: response.data.data,
                }
            });
            dispatch({type: "ALERT_SUCCESS", payload: {
                    message: "Teilnehmer Import erfolgreich."
                }});

        }).catch(error => {
            dispatch(errorBuilder(error));

        });
};

export const editParticipant = (participant_data) => async (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"});
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/participant/${participant_data.id}`, {
        name: participant_data.name,
        email: participant_data.email,
        party: participant_data.party
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {

            dispatch({
                type: "EDIT_PARTICIPANT",
                payload: {
                    participant: response.data.data,
                    id: participant_data.id,
                }

            });
    }).catch(error => {
        dispatch(errorBuilder(error));
    });
};

export const editParticipantDeputy = (participant_data) => async (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"});
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/participant/${participant_data.id}/deputy`, {
        deputyable_id: participant_data.deputyable_id,
        deputyable_type: participant_data.deputy_type
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "EDIT_PARTICIPANTS",
            payload: {
                participants: response.data.data,
                id: participant_data.id,
            }
        });
    }).catch(error => {
        dispatch(errorBuilder(error));
    });
};

export const changeParticipantPresents = (id, value) => async (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"});
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/participant/${id}/presence`, {
        present: value,
    }, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "EDIT_PARTICIPANT",
            payload: {
                participant: response.data.data,
                id: id
            }
        });
    }).catch(error => {
        dispatch(errorBuilder(error));
    });
};

export const getParticipantForDeputyVoteModalByMeeting = (meeting_id) => async (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"});
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/meeting/${meeting_id}/participants/flat`, {
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    })
        .then(response => {
            dispatch({
                type: "FETCH_MANUAL_PARTICIPANTS",
                payload: {
                    participants: response.data.data,
                }
            });
        }).catch(function (error) {
            dispatch(errorBuilder(error));
        });
}

export const forceParticipantLogout = (participant_id) => async (dispatch) => {
    dispatch({type:"PARTICIPANTS_LOADING"});
    await axios.patch(`${process.env.REACT_APP_API_ENDPOINT}/participant_logout/${participant_id}`,{},{
        headers: {
            'Authorization': "Bearer " + getToken()
        }
    }).then(response => {
        dispatch({
            type: "EDIT_PARTICIPANT",
            payload: {
                participant: response.data.participant,
                id: participant_id
            }
        });
    }).catch(function (error) {
        dispatch(errorBuilder(error));
    });

}